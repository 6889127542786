import React, { useEffect, useState, useRef } from "react";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import getCurrentDate from "../components/currentDate";

function ImageGallary() {
  const currentDate = getCurrentDate();
  const [loading, setLoading] = useState(true);
  const [todayDate, setTodayDate] = useState(currentDate);
  const [colSize, setColSize] = useState(3);
  const [images, setImages] = useState([]);
  const observer = useRef();
  const [page, setPage] = useState(1); // Track the current page number
  const [hasMore, setHasMore] = useState(true); // Track if there is more data to load
  const handleInputChange = (event) => {
    const value = parseInt(event.target.value); // Parse input value as integer
    setColSize(value); // Update colSize state with the new value
  };
  const handleSizeChange = (change) => {
    // Calculate the new size based on the change
    const newSize = Math.max(1, Math.min(12, colSize + change));
    setColSize(newSize);
  };
  const handleDateChange = (e) => {
    const newDate = e.target.value;
   
    if (newDate !== todayDate) {
      setPage(1);
      setTodayDate(newDate);
      setImages([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post("/getAllChartImages", {
        date: todayDate,
        page: page,
      })
      .then((response) => {
        const newImages = response.data.images;
        if (Array.isArray(newImages)) {
          setImages((prevImages) => [...prevImages, ...newImages]);
          setHasMore(newImages.length > 0);
        } else {
          console.error("Invalid data format received for images");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
        // Handle error, e.g., display a toast notification
        toast.error("Error fetching images. Please try again.");
        setLoading(false);
      });
  }, [todayDate, page]);

   
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);

    const bottomElement = document.querySelector("#bottom");
    if (bottomElement && observer.current) {
      observer.current.observe(bottomElement);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore]);

  // -----convert yyyy-mm-dd-- to -- dd-mm-yyyy----
  function formatDate(dateString) {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
  return (
    <div>
      <div className="row">
        <div className="col">
          <h3
            style={{ fontFamily: "serif", color: "lime", fontSize: "1.7rem" }}
          >
            IMAGE GALLERY
          </h3>
        </div>
        <div className="col">
          <input
            id="selectdate_dash"
            value={todayDate}
            onChange={handleDateChange}
            type="date"
            max={currentDate}
            style={{
              border: "none",
              outline: "none",
              background: "transparent",
              color: "lime",
              fontFamily: "Georgia, serif",
              fontSize: "1.4rem",
            }}
          />
        </div>
        <div className="col">
          <div className="input-group">
            <button
              className="btn btn-outline-secondary btn-lg fw-bold"
              type="button"
              onClick={() => handleSizeChange(1)}
            >
              <i className="fas fa-minus" style={{ color: "lime" }}></i>
            </button>
            <select
              // className="form-select"
              value={colSize}
              onChange={handleInputChange}
              style={{ width: "40px" }}
            >
              <option value={1}>12</option>
              <option value={2}>6</option>
              <option value={3}>4</option>
              <option value={4}>3</option>
              <option value={6}>2</option>
              <option value={12}>1</option>
            </select>
            <button
              className="btn btn-outline-secondary btn-lg fw-bold"
              type="button"
              onClick={() => handleSizeChange(-1)}
            >
              <i className="fas fa-plus" style={{ color: "lime" }}></i>
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        
      </div>
 
      {images.map((imageall, index) => (
        <div key={index}>
          <div className="row border rounded pt-2">
            <h4
              style={{
                fontFamily: "serif",
                color: "#ddffcc",
                fontSize: "1.5rem",
              }}
            >
              {formatDate(imageall.date)}
            </h4> 
            {imageall.images.map((imageurl, imageIndex) => (
                 imageurl.url && 
              <div
                key={imageIndex}
                className={`col-xs-6 col-sm-${colSize} d-flex`}
              >
                {imageurl.url ? (
                  <div className="card card_color">
                    <a
                        href={imageurl.url || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                    <img src={imageurl.url} className="card-img-top image_size" alt="" />
                    </a>
                    <div className="card-body">
                      <p className="card-text">{imageurl.name}</p>
                    </div>
                  </div>
                ) : null}
              </div>

            ))}
          </div>
        </div>
      ))}
      {/* <div className="bottom"></div>  */}
      {loading && <Spinner />}
      <div id="bottom"></div>
    </div>
  );
}

export default ImageGallary;
