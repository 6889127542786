import React from "react";
import Spinner from "../components/Spinner";

function AfterIndianMarketCard({
  cardContent,
  typeReq,
  loading,
  selectedAfterOptions,
  selectAfterIndia,
  handleChangeAftermarket,
  selectedAfterOptions2,
  selecs2,
}) {
  return (
    <>
      <div className="card flex-fill border-0 illustration card_color">
        <div
          className="card-body p-0 d-flex flex-fill"
          style={{ minWidth: "150px" }}
        >
          <div className="row g-0 w-100 card_color ">
            <div
              className="card-header"
              dangerouslySetInnerHTML={{ __html: cardContent.header }}
            ></div>
            {loading ? (
              <Spinner />
            ) : (
              <div className="card-body text-center">
                {typeReq && typeReq.radio === "YES" && (
                  <div
                    className="btn-group text-center border"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name={cardContent.name}
                      id={`${cardContent.name}_YES`}
                      autoComplete="off"
                      value="YES"
                      onChange={handleChangeAftermarket}
                      checked={selectedAfterOptions === "YES"}
                    />
                    <label
                      className="btn btn-outline-success"
                      htmlFor={`${cardContent.name}_YES`}
                    >
                      YES
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name={cardContent.name}
                      id={`${cardContent.name}_NO`}
                      autoComplete="off"
                      value="NO"
                      onChange={handleChangeAftermarket}
                      checked={selectedAfterOptions === "NO"}
                    />
                    <label
                      className="btn btn-outline-danger"
                      htmlFor={`${cardContent.name}_NO`}
                    >
                      NO
                    </label>
                  </div>
                )}
                {typeReq && typeReq.time === "YES" && (
                  <div
                    className="input-group date  mt-3"
                    id={`TimePicker${cardContent.name}_time`}
                    style={{ paddingTop: "1px" }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      name={`${cardContent.name}_time`}
                      value={selectAfterIndia[`${cardContent.name}_time`]}
                      onChange={handleChangeAftermarket}
                    />
                  </div>
                )}
                {typeReq && typeReq.dateTime === "YES" && (
                  <div
                    className="input-group date mt-3"
                    id={`datetimepicker${cardContent.name}_dateTime`}
                    style={{ paddingTop: "1px" }}
                  >
                    <input
                      type="datetime-local"
                      className="form-control"
                      name={`${cardContent.name}_dateTime`}
                      value={selectAfterIndia[`${cardContent.name}_dateTime`]}
                      onChange={handleChangeAftermarket} 
                    />
                  </div>
                )}
                {typeReq && typeReq.percentage === "YES" && (
                  <div
                    className="card-body text-center"
                    style={{ width: "100%" }}
                  >
                    <div className="row">
                      <div className="col-2">
                        <span style={{ color: "yellow" }}>%</span>
                      </div>
                      <div className="col-10">
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}_percent`}
                          step="25"
                          value={
                            selectAfterIndia[`${cardContent.name}_percent`]
                          }
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
                {typeReq && typeReq.cardOHLC === "YES" && (
                  <div
                    className="card-body text-center"
                    style={{ width: "100%" }}
                  >
                    <div className="row">
                      <div className="col-2">
                        <span style={{ color: "yellow" }}>O</span>
                      </div>
                      <div className="col-10">
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}`}
                          step="1"
                          value={selectAfterIndia[`${cardContent.name}`]}
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <span style={{ color: "red" }}>H</span>
                      </div>
                      <div className="col-10">
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}_H`}
                          step="1"
                          value={selectAfterIndia[`${cardContent.name}_H`]}
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <span style={{ color: "yellow" }}>L</span>
                      </div>
                      <div className="col-10">
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}_L`}
                          step="1"
                          value={selectAfterIndia[`${cardContent.name}_L`]}
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <span style={{ color: "red" }}>C</span>
                      </div>
                      <div className="col-10">
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}_C`}
                          step="1"
                          value={selectAfterIndia[`${cardContent.name}_C`]}
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
                {typeReq && typeReq.cardCallPut === "YES" && (
                  <div
                    className="card-body text-center"
                    style={{ width: "100%" }}
                  >
                    <div className="row" style={{ textAlign: "left" }}>
                      <span style={{ color: "yellow" }}>Call</span>
                      <input
                        type="number"
                        className="form-control"
                        name={`${cardContent.name}`}
                        step="1"
                        value={selectAfterIndia[`${cardContent.name}`]}
                        onChange={handleChangeAftermarket}
                        required
                      />{" "}
                    </div>
                    <div className="row" style={{ textAlign: "left" }}>
                      <span style={{ color: "red" }}>Put</span>
                      <input
                        type="number"
                        className="form-control"
                        name={`${cardContent.name}_PUT`}
                        step="1"
                        value={selectAfterIndia[`${cardContent.name}_PUT`]}
                        onChange={handleChangeAftermarket}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {typeReq && typeReq.cardNo === "2" && (
            <div className="row g-0 w-100 card_color">
              <div
                className="card-header"
                dangerouslySetInnerHTML={{ __html: cardContent.header_B }}
              ></div>
              {loading ? (
                <Spinner />
              ) : (
                <div className="card-body text-center">
                  {typeReq && typeReq.radio === "YES" && (
                    <div
                      className="btn-group text-center border"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name={`${cardContent.name}_B`}
                        id={`${cardContent.name}_B_YES`}
                        autoComplete="off"
                        value="YES"
                        onChange={handleChangeAftermarket} // Pass both name and value
                        checked={selectedAfterOptions2 === "YES"}
                      />
                      <label
                        className="btn btn-outline-success"
                        htmlFor={`${cardContent.name}_B_YES`}
                      >
                        YES
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name={`${cardContent.name}_B`}
                        id={`${cardContent.name}_B_NO`}
                        autoComplete="off"
                        value="NO"
                        onChange={handleChangeAftermarket} // Pass both name and value
                        checked={selectedAfterOptions2 === "NO"}
                      />
                      <label
                        className="btn btn-outline-danger"
                        htmlFor={`${cardContent.name}_B_NO`}
                      >
                        NO
                      </label>
                    </div>
                  )}
                  {typeReq && typeReq.time === "YES" && (
                    <div
                      className="input-group date mt-3"
                      id={`TimePicker${cardContent.name}_B_time`}
                      style={{ paddingTop: "1px" }}
                    >
                      <input
                        type="time"
                        className="form-control"
                        name={`${cardContent.name}_B_time`}
                        value={selectAfterIndia[`${cardContent.name}_B_time`]}
                        onChange={handleChangeAftermarket}
                      />
                    </div>
                  )}
                  {typeReq && typeReq.dateTime === "YES" && (
                    <div
                      className="input-group date  mt-3"
                      id={`datetimepicker_${cardContent.name}_B_dateTime`}
                      style={{ paddingTop: "1px" }}
                    >
                      <input
                        type="datetime-local"
                        className="form-control"
                        name={`${cardContent.name}_B_dateTime`}
                        value={
                          selectAfterIndia[`${cardContent.name}_B_dateTime`]
                        }
                        onChange={handleChangeAftermarket}
                      />
                    </div>
                  )}
                  {typeReq && typeReq.percentage === "YES" && (
                    <div
                      className="card-body text-center"
                      style={{ width: "100%" }}
                    >
                      <div className="row">
                        <div className="col-2">
                          <span style={{ color: "yellow" }}>%</span>
                        </div>
                        <div className="col-10">
                          <input
                            type="number"
                            className="form-control"
                            name={`${cardContent.name}_B_percent`}
                            step="25"
                            value={
                              selectAfterIndia[`${cardContent.name}_B_percent`]
                            }
                            onChange={handleChangeAftermarket}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {typeReq && typeReq.cardOHLC === "YES" && (
                    <div
                      className="card-body text-center"
                      style={{ width: "100%" }}
                    >
                      <div className="row">
                        <div className="col-2">
                          <span style={{ color: "yellow" }}>O</span>
                        </div>
                        <div className="col-10">
                          <input
                            type="number"
                            className="form-control"
                            name={`${cardContent.name}_B`}
                            step="1"
                            value={selectAfterIndia[`${cardContent.name}_B`]}
                            onChange={handleChangeAftermarket}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <span style={{ color: "red" }}>H</span>
                        </div>
                        <div className="col-10">
                          <input
                            type="number"
                            className="form-control"
                            name={`${cardContent.name}_B_H`}
                            step="1"
                            value={selectAfterIndia[`${cardContent.name}_B_H`]}
                            onChange={handleChangeAftermarket}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <span style={{ color: "yellow" }}>L</span>
                        </div>
                        <div className="col-10">
                          <input
                            type="number"
                            className="form-control"
                            name={`${cardContent.name}_B_L`}
                            step="1"
                            value={selectAfterIndia[`${cardContent.name}_B_L`]}
                            onChange={handleChangeAftermarket}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <span style={{ color: "red" }}>C</span>
                        </div>
                        <div className="col-10">
                          <input
                            type="number"
                            className="form-control"
                            name={`${cardContent.name}_B_C`}
                            step="1"
                            value={selectAfterIndia[`${cardContent.name}_B_C`]}
                            onChange={handleChangeAftermarket}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {typeReq && typeReq.cardCallPut === "YES" && (
                    <div
                      className="card-body text-center"
                      style={{ width: "100%" }}
                    >
                      <div className="row" style={{ textAlign: "left" }}>
                        <span style={{ color: "yellow" }}>Call</span>
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}_B_CALL`}
                          step="1"
                          value={selectAfterIndia[`${cardContent.name}_B_CALL`]}
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                      <div className="row" style={{ textAlign: "left" }}>
                        <span style={{ color: "red" }}>Put</span>
                        <input
                          type="number"
                          className="form-control"
                          name={`${cardContent.name}_B_PUT`}
                          step="1"
                          value={selectAfterIndia[`${cardContent.name}_B_PUT`]}
                          onChange={handleChangeAftermarket}
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        
      </div>
    </>
  );
}

export default AfterIndianMarketCard;
