import React, { useState } from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  const [calenderOpen, setCalenderOpen] = useState(false);
  return (
    <div className="h-100 position-fixed">
      <div className="sidebar-logo">
        <Link to="/">URS-KART</Link>
      </div>
      <ul className="sidebar-nav">
        <li className="sidebar-item">
          <Link to="/" className="sidebar-link">
            <i className="fa-solid fa-list pe-2"></i>
            Dashboard
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/dataentry" className="sidebar-link">
            <i className="fa-solid fa-file-lines pe-2"></i>
            Data Entry
          </Link>
        </li>
       
        <li className="sidebar-item">
          <Link to="/filterData" className="sidebar-link">
            <i className="fa-solid fas fa-filter pe-2"></i>
            Filter
          </Link>
        </li>
       
        <li className="sidebar-item">
          <Link to="/ImageGallary" className="sidebar-link">
            <i className="fa-solid fas fa-image pe-2"></i>
            Image Gallary
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/DataTableExcel" className="sidebar-link">
            <i className="fa-solid fas fa-image pe-2"></i>
            Excel Data
          </Link>
        </li> 
       
        <li className="sidebar-item">
          {/* <Link
            // to="/"
            className="sidebar-link collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#calender"
            aria-expanded="false"
          >
            <i className="fa-solid fas fa-calendar pe-2"></i>Calender
          </Link> */}
          <Link
            className={`sidebar-link ${calenderOpen ? "" : "collapsed"}`}
            onClick={() => setCalenderOpen(!calenderOpen)}
            aria-expanded={calenderOpen ? "true" : "false"}
          >
            <i className="fa-solid fas fa-calendar pe-2"></i>Calender
          </Link>
          {/* <ul
            id="calender"
            className="sidebar-dropdown list-unstyled collapse" 
            data-bs-parent="#sidebar"
          > */}
          <ul
            className={`sidebar-dropdown list-unstyled ${
              calenderOpen ? "show" : "collapse"
            }`}
          >
            <li className="sidebar-item">
              <Link
                to="/ind-event-calender"
                data-page="ind-event-calender"
                id="ind-event-calender"
                className="sidebar-link page_change"
              >
                &#9658; {/* HTML entity for right-pointing arrow bullet */}
                <span className="link-text">Ind-Economy Event</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/ind-Holiday-calender"
                data-page="ind-Holiday-calender"
                className="sidebar-link page_change"
              >
                 &#9658;  
                <span className="link-text">Ind-Market Holiday</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/us-event-calender"
                data-page="us-event-calender"
                id="us-event-calender"
                className="sidebar-link page_change"
              >
               &#9658;  
                <span className="link-text"> US-Economy Event</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/us-Holiday-calender"
                data-page="us-Holiday-calender"
                className="sidebar-link page_change"
              >
                &#9658;  
                <span className="link-text">US-Market Holiday</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/Nifty-Heavy-Weightage-Stock-Result-Calender"
                data-page="/Nifty-Heavy-Weightage-Stock-Result-Calender"
                className="sidebar-link page_change"
              >
                &#9658;  
                <span className="link-text">Nifty Weightage</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link
                to="/Banknifty-Heavy-Weightage-Stock-Result-Calender"
                data-page="/Banknifty-Heavy-Weightage-Stock-Result-Calender"
                className="sidebar-link page_change"
              >
                &#9658;  
                <span className="link-text">Banknifty Weightage</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="sidebar-item">
          <Link
            to="/"
            className="sidebar-link collapsed"
            data-bs-target="#posts"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            <i className="fa-solid fa-sliders pe-2"></i>
            Analysis
          </Link>
          <ul
            id="posts"
            className="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar"
          >
            <li className="sidebar-item">
              <Link to="/ncograph" className="sidebar-link">
                NCO Graph
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/ncoanalysis" className="sidebar-link">
                NCO Analysis
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/" className="sidebar-link">
                Post 3
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-item">
          <Link
            to="/"
            className="sidebar-link collapsed"
            data-bs-target="#auth"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            <i className="fa-regular fa-user pe-2"></i>
            Auth
          </Link>
          <ul
            id="auth"
            className="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar"
          >
            <li className="sidebar-item">
              <Link to="/login" className="sidebar-link">
                Login
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/tradeRegister" className="sidebar-link">
                Register
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/changePassword" className="sidebar-link">
                change Password
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-header">Multi Level Menu</li>
        <li className="sidebar-item">
          <Link
            to="/"
            className="sidebar-link collapsed"
            data-bs-target="#multi"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            <i className="fa-solid fa-share-nodes pe-2"></i>
            Multi Dropdown
          </Link>
          <ul
            id="multi"
            className="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar"
          >
            <li className="sidebar-item">
              <Link
                to="/"
                className="sidebar-link collapsed"
                data-bs-target="#level-1"
                data-bs-toggle="collapse"
                aria-expanded="false"
              >
                Level 1
              </Link>
              <ul
                id="level-1"
                className="sidebar-dropdown list-unstyled collapse"
              >
                <li className="sidebar-item">
                  <Link to="/" className="sidebar-link">
                    Level 1.1
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/" className="sidebar-link">
                    Level 1.2
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
