import React from 'react'
import BeforeIndianMarketExcel from './exceldownloadpages/BeforeIndianMarketExcel'
import IndianLiveMarketExcel from './exceldownloadpages/IndianLiveMarketExcel'
import NiftyAndBnfExcel from './exceldownloadpages/NiftyAndBnfExcel'
import AfterIndianMarketExcel from './exceldownloadpages/AfterIndianMarketExcel'
import AfterMainGlobalMarket from '../pages/AfterMainGlobalMarket'
import AfterMainGlobalMarketExcel from './exceldownloadpages/AfterMainGlobalMarketExcel'
import MajorNews from '../pages/MajorNews'
import MajorNewsExcel from './exceldownloadpages/MajorNewsExcel'
import IndianMarketADRExcel from './exceldownloadpages/IndianMarketADRExcel'
import IndianEconomyEventCalenderExcel from './exceldownloadpages/IndianEconomyEventCalenderExcel'
import IndianMarketHolidayCalender from './exceldownloadpages/IndianMarketHolidayCalenderExcel'
import IndianMarketHolidayCalenderExcel from './exceldownloadpages/IndianMarketHolidayCalenderExcel'
import USEconomyEventCalenderExcel from './exceldownloadpages/USEconomyEventCalenderExcel'
import USMarketHolidayCalenderExcel from './exceldownloadpages/USMarketHolidayCalenderExcel'
import NiftyHeavyWeightageStockResultCalenderExcel from './exceldownloadpages/NiftyHeavyWeightageStockResultCalenderExcel'
import BankNiftyHeavyWeightageStockResultCalenderExcel from './exceldownloadpages/BankNiftyHeavyWeightageStockResultCalenderExcel'
import NcoNiftyCeExcel from './exceldownloadpages/NcoNiftyCeExcel'
import NcoNiftyPeExcel from './exceldownloadpages/NcoNiftyPeExcel'
import NcoBnfCeExcel from './exceldownloadpages/NcoBnfCeExcel'
import NcoBnfPeExcel from './exceldownloadpages/NcoBnfPeExcel'

function DataTableExcel() {
  return (
    <div className='container p-5'>        
        <BeforeIndianMarketExcel />
        <IndianLiveMarketExcel />
        <NiftyAndBnfExcel />
        <AfterIndianMarketExcel />
        <AfterMainGlobalMarketExcel />
        <MajorNewsExcel />
        <IndianMarketADRExcel />
        <IndianEconomyEventCalenderExcel />
        <IndianMarketHolidayCalenderExcel />
        <USEconomyEventCalenderExcel />
        <USMarketHolidayCalenderExcel />
        <NiftyHeavyWeightageStockResultCalenderExcel />
        <BankNiftyHeavyWeightageStockResultCalenderExcel />
        <NcoNiftyCeExcel />
        <NcoNiftyPeExcel />
        <NcoBnfCeExcel />
        <NcoBnfPeExcel />
    </div>
  )
}

export default DataTableExcel