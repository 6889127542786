import React, { useState, useEffect } from "react";
// import LineGraph from "../charts/LineGraph";
import "../charts/ModalFullScreen.css";
import getCurrentDate from "../components/currentDate";
import NcoAnalysisGraph from "./NcoAnalysisGraph";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";

function NcoAnalysis() {
  const [loading, setLoading] = useState(true);
  const currentDate = getCurrentDate();
  const [todayDate, setTodayDate] = useState(currentDate);
  const [niftyCeDate, setNiftyCeDate] = useState([]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    if (newDate !== todayDate) {
      setTodayDate(newDate);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post("/ncofindexpday", {
        date: todayDate,
      })
      .then((response) => { 
        setNiftyCeDate(response.data.niftyce);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [todayDate]);

  return (
    <div className="container font-italic">
      <h5>
        <span style={{ color: "lime" }}>NCO ANALYSIS </span>
        <input
          id="selectdate_dash"
          value={todayDate}
          onChange={handleDateChange}
          type="date"
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
            color: "lime",
          }}
          // max={currentDate}
        />
      </h5>

      <div className="row justify-content-center mt-3">
        <div className="row mt-3 border p-3">
            <h5>NCO GRAPH</h5>
          <NcoAnalysisGraph analysisDate={todayDate} />
        </div>
        <div className="row mt-3 border p-3">
            <h5>NCO GRAPH</h5>
          <NcoAnalysisGraph analysisDate={niftyCeDate} />
        </div>
      </div>
    </div>
  );
}

export default NcoAnalysis;
