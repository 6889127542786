import React from 'react'
import Spinner from '../components/Spinner'

function MajorNewsCard({cardContent,selectMajorNews,handleMajorNews,loading}) {
  return (
    <div className="card flex-fill border-0 illustration">
    <div
      className="card-body p-0 d-flex flex-fill"
      style={{ minWidth: "150px" }}
    >
      <div className="row g-0 w-100 card_color">
        <div
          className="card-header"
          dangerouslySetInnerHTML={{ __html: cardContent.header }}
        />
     {
          loading ? <Spinner /> :
        <div className="card-body text-center">
       
            <div
              className="btn-group text-center border"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name={cardContent.name}
                id={`${cardContent.name}_YES`}
                autoComplete="off"
                value="YES"
                onChange={handleMajorNews} // Pass both name and value
                checked={selectMajorNews === "YES"}
              />
              <label
                className="btn btn-outline-success"
                htmlFor={`${cardContent.name}_YES`}
              >
                YES
              </label>
              <input
                type="radio"
                className="btn-check"
                name={cardContent.name}
                id={`${cardContent.name}_NO`}
                autoComplete="off"
                value="NO"
                onChange={handleMajorNews} // Pass both name and value
                checked={selectMajorNews === "NO"}
              />
              <label
                className="btn btn-outline-danger"
                htmlFor={`${cardContent.name}_NO`}
              >
                NO
              </label>
            </div>
          
 
        </div>
        }
      </div>
    </div>
  </div>
  )
}

export default MajorNewsCard