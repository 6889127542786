import React, { useState, useEffect } from "react";
import axios from "../../pages/axios";
import * as XLSX from "xlsx";
import "./cardStyle.css";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

function AfterIndianMarketExcel() {
    
  const [data, setData] = useState([]);
  const [buttonDownload, setbuttonDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    setbuttonDownload(false);
    setTimeout(() => {
      axios
        .post("/excelmysqldownload", { valuetype: "after_indian_market_time" })
        .then((response) => { 
          setData(response.data.data);
          if (response.data.check === "YES") {
            setData(response.data.data);
            setbuttonDownload(true);
          } else {
            toast.error("Download Failed");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Server Error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 2000); // 3-second delay
  };

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert table data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create custom table headings
    const customHeadings = [
      "ID",
      "Date",
      "IF NIFTY OH In Call Side  Hit…? % Last Hit Time", 
      "IF NIFTY OH In PUT Side Hit…? % Last Hit Time", 
      "IF BNF OH In Call Side Hit…? % Last Hit Time",
      "IF BNF OH In PUT Side Hit…? % Last Hit Time", 
      "IF NIFTY OL In PUT Side Hit…? % Last Hit Time", 
      "IF NIFTY OL In Call Side Hit…? % Last Hit Time",
      "IF BNF OL In PUT Side Hit…? % Last Hit Time", 
      "IF BNF OL In Call Side Hit…? % Last Hit Time", 
      "Nifty opening Gap Filled  %",
      "Bank Nifty opening Gap Filled %", 
      "Is Nifty Future OH Hit", 
      "Is  Nifty Future OL Hit", 
      "Is BNF Future OH Hit", 
      "Is  BNF Future OL Hit", 
      "Nifty Spot OHLC Values",
      "Nifty Future OHLC Values", 
      "BNF Spot OHLC Values", 
      "BNF Future OHLC Values",
      "INDIA VIX OHLC Values", 
      "Nifty ATM Premioum", 
      "BNF ATM Premium",
      "Created At",
      "Updated At",
    ]; // Example custom headings

    // Add custom headings to the worksheet
    XLSX.utils.sheet_add_aoa(worksheet, [customHeadings], { origin: "A1" });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const formatTimestamp = () => {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear().toString();
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    const timestamp = formatTimestamp();

    // Construct filename with timestamp
    const filename = `After Indian Market Time_${timestamp}.xlsx`;

    // Save the workbook as an Excel file with the constructed filename
    XLSX.writeFile(workbook, filename);
  };

  return (
    <div className="row mt-3">
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: "white",
            color: "green",
          },
        }}
      />
      <div className="card-body card_color excel_card_border_curve p-2">
        <div className="container">
          <div className="row align_center">
            <div className="col-5">
              <h5 className="card-title" style={{ color: "#ECB176" }}>
                AFTER INDIAN MARKET TIME
              </h5>
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              {isLoading && <div className="loader"></div>}
            </div>
            <div className="col-4 d-flex justify-content-center">
              <div
                className="card-body button_card_color p-2"
                style={{ height: "80px", maxWidth: "200px" }}
              >
                {buttonDownload ? (
                  <button
                    className="bn31"
                    onClick={exportToExcel}
                    disabled={isLoading}
                  >
                    <span className="bn31span">Download</span>
                  </button>
                ) : (
                  <button
                    className="bn31"
                    onClick={fetchData}
                    disabled={isLoading}
                  >
                    <span className="bn31span">Create Excel</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AfterIndianMarketExcel