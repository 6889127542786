import React from 'react'
 

// currentDate.js
const getCurrentDate = () => {
  // const currentDate = new Date().toLocaleDateString(); // Get current date in format MM/DD/YYYY
  const newDate = new Date();
  const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    const hours = String(newDate.getHours()).padStart(2, "0");

    const currentDate = `${year}-${month}-${day}`;
  return currentDate;
};

export default getCurrentDate;
