import React, { useState, useEffect } from "react";
import IndianLiveMarketCard from "./IndianLiveMarketCard";
import axios from "./axios";
import Spinner from '../components/Spinner'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function IndianLiveMarket({ selectedDate }) {

  const [loading,setLoading] = useState(true);
  const [dataExist,setDataExist] = useState('NO');
  const [selectedOptions, setSelectedOptions] = useState({
    date : selectedDate,
    Braw1col1btnradio1: '-0.02',
    Braw1col2btnradio2: '-0.02',
    Braw1col3btnradio3: "NO",
    Braw1col4btnradio4: "NO",
    Braw1col5btnradio5: "NO",
    Braw1col5btnradio5_percent: "0",
    Braw1col6btnradio6: "NO",
    Braw1col6btnradio6_percent: "0",
    Braw2col1btnradio1: "NO",
    Braw2col1btnradio1_percent: "0",
    Braw2col2btnradio2: "NO",
    Braw2col2btnradio2_percent: "0",
    Braw2col3btnradio3: "NO",
    Braw2col3btnradio3_percent: "0",
    Braw2col4btnradio4: "NO",
    Braw2col4btnradio4_percent: "0",
    Braw2col5btnradio5: "NO",
    Braw2col5btnradio5_percent: "0",
    Braw2col6btnradio6: "NO",
    Braw2col6btnradio6_percent: "0",
    Braw3col1btnradio1: "NO",
    Braw3col2btnradio2: "NO",
    Braw3col3btnradio3: "NO",
    Braw3col4btnradio4: "NO",
    Braw3col5btnradio5: "NO",
    Braw3col6btnradio6: "NO",
    Braw3col6btnradio6_radio: "NO",
    Braw4col1btnradio1: "NO",
    Braw4col2btnradio2: "NO",
    Braw4col2btnradio2_radio: "NO",
  });

  useEffect(() => {
    setLoading(true)
    // console.log(selectedDate)
    axios
      .post("/livemarket-check-date", {
        date: selectedDate,
      })
      .then((response) => {
        if(response.data.status === 'YES'){
          // console.log('ok')
        setDataExist('YES')
        }else{
          setDataExist('NO')
          // console.log('fail')
        }
        setSelectedOptions(response.data.items);
        // console.log(response.data.status)
        // console.log(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      
  }, [selectedDate]);

   
  const handleOptionChange = (e) => {
    const { name,value } = e.target;
    setSelectedOptions((prevData)=>({
      ...prevData,
      [name]: value
    })
    )
  //  console.log(selectedOptions)
  };
   
 
  const liveSubmit = (event) => {
    event.preventDefault(); 
    const data = {
      ...selectedOptions,
      dateFind: selectedDate  
    };
    let dateRaw = dataExist;
    setLoading(true)
    const apiUrl = dateRaw !== 'NO' ? `/live-Edit/${selectedDate}` : '/live-create';
    axios({
      method: dateRaw !== 'NO'  ? 'put' : 'post',
      url: apiUrl,
      data: data
    })
    .then(response => {
       
      if(response.data.status === 'YES'){
        setDataExist('YES')
        }else{
          setDataExist('NO')
        }
      setLoading(false)
      toast.success(response.data.message); 
    })
    .catch(error => {
      console.error("Error sending data:", error);
      toast.error('Wrong Data Entered');
          setLoading(false)
    });

  };
  
   

  const m1Entries = [
    {
      name: "Braw1col1btnradio1",
      header: 'Nifty opening <span style="color: red"> Price</span>',
      type: {radio : 'NO' , decimel : 'YES' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'} 
    },
    {
      name: "Braw1col2btnradio2",
      header: 'Banknifty opening <span style="color: red">Price</span>',
      type: {radio : 'NO' , decimel : 'YES' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'} 
    },
    {
      name: "Braw1col3btnradio3",
      header:
        'Is all <span style="color: red">Nifty Weighted</span> Stockes in Top Lossers or Gainers List',
        type: {radio : 'NO' , decimel : 'NO' , percent: 'NO' , radioGain : 'YES' , radioDouble : 'NO'}
    },
    {
      name: "Braw1col4btnradio4",
      header:
        'Is all <span style="color: red">Bank Nifty</span> Weighted Stockes in Top Lossers or Gainers List',
        type: {radio : 'NO' , decimel : 'NO' , percent: 'NO' , radioGain : 'YES' , radioDouble : 'NO'}
    },
    {
      name: "Braw1col5btnradio5",
      header: '<span style="color: red">NIFTY OH </span>In Call Side',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'}
    },
    {
      name: "Braw1col6btnradio6",
      header: '<span style="color: red">NIFTY OH </span>In PUT Side',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'}
    },
    { name: "Braw2col1btnradio1", header: 'BNF OH <span style="color: red">In Call Side</span>',
     type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'} },
     { name: "Braw2col2btnradio2", header: 'BNF OH <span style="color: red">In PUT Side</span>',
     type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'} },
    {
      name: "Braw2col3btnradio3",
      header: '<span style="color: red">NIFTY OL</span> In PUT Side',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'}
    },
    {
      name: "Braw2col4btnradio4",
      header: ' <span style="color: red">NIFTY OL </span>In Call Side',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'}
    },
    { name: "Braw2col5btnradio5", header: 'BNF OL <span style="color: red">In PUT Side</span>',
    type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'}},
    {
      name: "Braw2col6btnradio6",
      header: ' <span style="color: red">BNF OL </span>In Call Side',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'YES' , radioGain : 'NO' , radioDouble : 'NO'}
    },
    {
      name: "Braw3col1btnradio1",
      header: 'Is <span style="color: red">Nifty Future</span> have OH',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'} 
    },
      
     
    {
      name: "Braw3col2btnradio2",
      header: 'Is Nifty Future have <span style="color: red">OL</span>',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'} 
    },
    {
      name: "Braw3col3btnradio3",
      header: 'Is <span style="color: red">BNF Future</span> have OH',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'} 
    },
    {
      name: "Braw3col4btnradio4",
      header: 'Is BNF Future<span style="color: red"> have OL</span>',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'} 
    },
    { name: "Braw3col5btnradio5", header: 'Nifty <span style="color: red">ATH</span> Day',type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'NO'}  },
    {
      name: "Braw3col6btnradio6",
      header: 'NIFTY<span style="color: red"> 52W High/Low </span>DAY',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'YES'} 
    },
    { name: "Braw4col1btnradio1", header: 'BNF <span style="color: red">ATH </span>Day',type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'N0'}  },
    {
      name: "Braw4col2btnradio2",
      header: 'BNF<span style="color: red"> 52W High/Low </span>DAY',
      type: {radio : 'YES' , decimel : 'NO' , percent: 'NO' , radioGain : 'NO' , radioDouble : 'YES'} 
    }
  ];


  return (
    <div className="container-fluid border rounded pt-3">
      <ToastContainer
          position="top-left"
          autoClose={1000}
          closeOnClick={true}
        />
      <h3>Indian Live Market Time( 9:15 to 15:30)</h3>
      
      <form onSubmit={liveSubmit} className="border-bottom border-danger p-3" >
        <div className="row">
        
          {m1Entries.map((entry, index) => (
            <div key={index} className="col-xs-6 col-sm-4 col-lg-2 d-flex">
              <IndianLiveMarketCard
                cardContent={{
                  header: entry.header,
                  name : entry.name
                }}
                handleOptionChange={handleOptionChange}
                selectedOption={selectedOptions[entry.name]}
                selectedOptions2 = {selectedOptions}
                typeReq = {  entry.type} 
                loading = {loading}
              />
            </div>
          ))}
        </div>
        {
            loading ? <Spinner /> :
        <button
          type="submit"
          className="btn btn-success card_color blink"
          // data-toggle="button"
          aria-pressed="false"
        >
          Submit          
        </button>
        
        }
        
      </form>
     <br />
    </div>
  );
}

export default IndianLiveMarket;
