import React from "react";
import MorningEntry from "../pages/MorningEntry";
import IndianLiveMarket from "../pages/IndianLiveMarket";
import NiftyAndBnf from "../pages/NiftyAndBnf";
import AfterIndianMarket from "../pages/AfterIndianMarket"; 
import IndianMarketADR from "../pages/IndianMarketADR";
import AfterMainGlobalMarket from "../pages/AfterMainGlobalMarket";
import MajorNews from "../pages/MajorNews";

function DataEntryRoute({ currentTime, selectedDate, currentDateTime }) {
  const scrollToComponent = (componentId) => {
    const element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
     <div className="row">
         
        <div className="col">
          <div className="row">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                onClick={() => scrollToComponent("component1")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio1">
                Before Market
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                onClick={() => scrollToComponent("component2")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio2">
                Live Market
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio3"
                autoComplete="off"
                onClick={() => scrollToComponent("component3")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio3">
                NiftyBnf
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio4"
                autoComplete="off"
                onClick={() => scrollToComponent("component4")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio4">
                After Market
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio5"
                autoComplete="off"
                onClick={() => scrollToComponent("component5")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio5">
                Global
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio6"
                autoComplete="off"
                onClick={() => scrollToComponent("component6")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio6">
                Major News
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio7"
                autoComplete="off"
                onClick={() => scrollToComponent("component7")}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio7">
                ADR
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* --------------M1 entry------------ */}
      {/* <div className="container-fluid border rounded pt-3"> */}
      <div id="component1">
        {selectedDate && (
      <MorningEntry selectedDate={selectedDate} currentDateTime={currentDateTime} />
    )}
      </div>
      <div id="component2">
        {selectedDate && (
          <IndianLiveMarket
            selectedDate={selectedDate}
            currentDateTime={currentDateTime}
          />
        )}
      </div>
      <div id="component3">
        {selectedDate && <NiftyAndBnf selectedDate={selectedDate} />}
      </div>
      <div id="component4">
        {selectedDate && (
          <AfterIndianMarket
            selectedDate={selectedDate}
            currentTime={currentTime}
            currentDateTime={currentDateTime}
          />
        )}
      </div> 

      <div id="component5">
      {selectedDate && (
        <AfterMainGlobalMarket
          selectedDate={selectedDate}
        />
        )}
      </div>
      <div id="component6">
      {selectedDate && (
        <MajorNews
          selectedDate={selectedDate}
        />
        )}
      </div>


      <div id="component7">
      {selectedDate && (
        <IndianMarketADR
          selectedDate={selectedDate}
        />
        )}
      </div>
    </>
  );
}

export default DataEntryRoute;
