import React from "react";
import Spinner from '../components/Spinner'

function NiftyAndBnfCard({ cardContent, typeReq, handleChangeNifty ,selectedNiftyOption2,selectedNiftyOption,loading}) {
  return (
    <div className="card flex-fill border-0 illustration">
      <div
        className="card-body p-0 d-flex flex-fill"
        style={{ minWidth: "150px" }}
      >
        <div className="row g-0 w-100 card_color">
          <div
            className="card-header"
            dangerouslySetInnerHTML={{ __html: cardContent.header }}
          />
       {
            loading ? <Spinner /> :
          <div className="card-body text-center">
            {typeReq && typeReq.radio === "YES" && (
              <div
                className="btn-group text-center border"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name={cardContent.name}
                  id={`${cardContent.name}_YES`}
                  autoComplete="off"
                  value="YES"
                  onChange={handleChangeNifty} // Pass both name and value
                  checked={selectedNiftyOption === "YES"}
                />
                <label
                  className="btn btn-outline-success"
                  htmlFor={`${cardContent.name}_YES`}
                >
                  YES
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name={cardContent.name}
                  id={`${cardContent.name}_NO`}
                  autoComplete="off"
                  value="NO"
                  onChange={handleChangeNifty} // Pass both name and value
                  checked={selectedNiftyOption === "NO"}
                />
                <label
                  className="btn btn-outline-danger"
                  htmlFor={`${cardContent.name}_NO`}
                >
                  NO
                </label>
              </div>
            )}

            {typeReq && typeReq.selectOption === "YES" && (
              <div className="" >
                <select
                  className="form-select"
                  name={cardContent.name}
                  value={selectedNiftyOption2[cardContent.name]}
                  onChange={handleChangeNifty}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            )}
          </div>
          }
        </div>
      </div>
    </div>
  );
}

export default NiftyAndBnfCard;
