import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/Modal";
import CloseIcon from "@mui/icons-material/Cancel";

function TradeJournnel({ selectedDate }) {
  const boxdivStyle = {
    border: '2px solid #007bff',
    boxShadow: '0 4px 6px rgba(0, 123, 255, 0.3)',
    padding: '10px',
    borderRadius: '5px',
    minWidth: '200px',
    maxWidth: '200px'
  };
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  const [selectTradeDate, setSelectTradeDate] = useState(selectedDate);
 
  //   const [selectTradeDate, setSelectTradeDate] = useState(formattedDate);
  const [loading, setLoading] = useState(true);
  const [rowsTable, setrowsTable] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [TradeJournalOption, setTradeJournalOption] = useState({
    date: selectTradeDate,
    broker_name: "",
    opening_capital: "",
    symbol_name: "",
    symbol_value: "",
    type: "ce",
    buy_sell: "buy",
    intraday: "intraday",
    conviction_type: "fullday",
    analysis_result: "0",
    personnel_conviction: "",
    reason_for_trade: "",
    quantity: "",
    trade_nature: "normal",
    entry_average: "",
    first_entry_time: formattedTime,
    max_stop_loss: "",
    exp_target: "",
    exit_price: "",
    exit_nature: "full",
    exit_time: formattedTime,
    exit_reason: "",
    max_loss_seen: "",
    max_profit_seen: "",
    highest_loss_seen_afterexit: "",
    missed_profit_afterexit: "",
    satisfaction_grade: "0",
    learning_from_trade: "",
    PLpts: "",
    PLamt: "",
    PLpercentage: "",
  });
  const [EventToEdit, setEventToEdit] = useState(null);

  useEffect(() => {
    if (!selectTradeDate) return;
    setLoading(true);
    axios
      .post("/trade-journal-check-date", {
        date: selectedDate,
      })
      .then((response) => {  
        if (response.data.status === "YES") {
          setTradeJournalOption(response.data.items);
        } else {
          setTradeJournalOption({
            date: selectTradeDate,
            broker_name: "",
            opening_capital: "",
            symbol_name: "",
            symbol_value: "",
            type: "ce",
            buy_sell: "buy",
            intraday: "intraday",
            conviction_type: "fullday",
            analysis_result: "0",
            personnel_conviction: "",
            reason_for_trade: "",
            quantity: "",
            trade_nature: "normal",
            entry_average: "",
            first_entry_time: formattedTime,
            max_stop_loss: "",
            exp_target: "",
            exit_price: "",
            exit_nature: "full",
            exit_time: formattedTime,
            exit_reason: "",
            max_loss_seen: "",
            max_profit_seen: "",
            highest_loss_seen_afterexit: "",
            missed_profit_afterexit: "",
            satisfaction_grade: "0",
            learning_from_trade: "",
            PLpts: "",
            PLamt: "",
            PLpercentage: "",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectedDate,selectTradeDate]);

  
  useEffect(() => {
    tableDatFetch();
  }, []);
  const tableDatFetch = () => {
    setLoading(true);
    axios
      .get("/trade-journal-table")
      .then((response) => {
        setrowsTable(response.data);
        // console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEventChange = (event) => {
    const { name, value } = event.target;
    if (name === "tradedate") {
      setSelectTradeDate(value);
    }
    setTradeJournalOption((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEdittChange = (event) => {
    const { name, value } = event.target;

    setEventToEdit((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const eventFormSubmit = (event) => {
    event.preventDefault();
    // console.log(TradeJournalOption);
    setLoading(true);
    const apiUrl = "/trade-journal-create";
    axios({
      method: "post",
      url: apiUrl,
      data: TradeJournalOption,
    })
      .then((response) => {
        // console.log(response); 
        if (response.data.status === "YES") {
          tableDatFetch();
        }
        setLoading(false);
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess("Wrong Data Entered");
        setLoading(false);
      });
  };
  const editFormSubmit = (event) => {
    event.preventDefault();
    const id = EventToEdit["id"];
    const apiUrl = `/trade-journal-Edit/${id}`;
    axios({
      method: "put",
      url: apiUrl,
      data: EventToEdit,
    })
      .then((response) => {
        // tableDatFetch();
        const updatedRows = rowsTable.map((row) =>
          row.id === id ? { ...row, ...EventToEdit } : row
        );
        setrowsTable(updatedRows);

        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess("Wrong Data Entered");
        setLoading(false);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "date",
      headerName: "Date",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "broker_name",
      headerName: "Brocker Name",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "opening_capital",
      headerName: "Opening Capital",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "symbol_name",
      headerName: "Symbol Name",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "symbol_value",
      headerName: "Symbol Value",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "type",
      headerName: "Type",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "buy_sell",
      headerName: "Buy/Sell",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "intraday",
      headerName: "Intraday/CNC",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "conviction_type",
      headerName: "Conviction Type",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "analysis_result",
      headerName: "AI Analysis Result %",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "personnel_conviction",
      headerName: "Personnel Conviction",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "reason_for_trade",
      headerName: "Reason for the Trade",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "trade_nature",
      headerName: "Trade Nature ",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "entry_average",
      headerName: "Entry Avg",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "first_entry_time",
      headerName: "First Entry Time",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "max_stop_loss",
      headerName: "Max Stop Loss ",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "exp_target",
      headerName: "Exp. Target ",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "exit_price",
      headerName: "Exit Price",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "exit_nature",
      headerName: "Exit Nature",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "exit_time",
      headerName: "Exit Date & Time",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "exit_reason",
      headerName: "Exit Reason",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "max_loss_seen",
      headerName: "Max Loss Seen",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "max_profit_seen",
      headerName: "Max Profit Seen",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "highest_loss_seen_afterexit",
      headerName: "Highest Loss Seen After Exit",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "missed_profit_afterexit",
      headerName: "Missed Profit  After Exit",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "satisfaction_grade",
      headerName: "Satisfaction Grade",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "learning_from_trade",
      headerName: "Learning From the trade",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "PLpts",
      headerName: "P/L Pts",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "PLamt",
      headerName: "P/L Amt",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "PLpercentage",
      headerName: "P/L %",
      wwidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "edit",
      headerName: "Edit",
      wwidth: 100,
      renderCell: (params) => (
        <button onClick={() => handleEditClick(params.row.id)}>Edit</button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      wwidth: 100,
      renderCell: (params) => (
        <button onClick={() => handleDeleteClick(params.row.id)}>Delete</button>
      ),
    },
  ];
  const notifySuccess = (data) => {
    if (typeof data === "string" && data.toLowerCase() === "error value") {
      toast.error("Wrong Data Entered");
    } else {
      toast.success(data);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setEventToEdit(null);
  };
  const handleEditClick = (id) => {
    const event = rowsTable.find((row) => row.id === id);
    setEventToEdit(event);
    setIsOpen(true);
  };
  const handleDeleteClick = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      setLoading(true);
      axios
        .delete(`/trade-journal/${id}`)
        .then((response) => {
          setrowsTable(rowsTable.filter((row) => row.id !== id));
          // tableDatFetch();
          toast.success(response.data.message);
        })
        .catch((error) => {
          console.error("Error deleting event:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.success("Deletion cancelled.");
    }
  };
  return (
    <div className="container">
      <ToastContainer autoClose={1000} />
      {loading && <Spinner />}
      <form onSubmit={eventFormSubmit} className="border border-1 p-3">
        <div className="row">
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Date:</label>
            <input
              className="form-control"
              type="date"
              name="date"
              value={selectedDate}
              onChange={handleEventChange}
              readOnly
            />
          </div>

          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Brocker name:</label>
            <input
              className="form-control"
              name="broker_name"
              type="text"
              placeholder="Name..."
              value={TradeJournalOption["broker_name"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Opening Capital:</label>
            <input
              className="form-control"
              name="opening_capital"
              type="number"
              placeholder="Openingcapital..."
              value={TradeJournalOption["opening_capital"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Symbol Name:</label>
            <input
              className="form-control"
              name="symbol_name"
              type="text"
              placeholder="Symbol Name..."
              value={TradeJournalOption["symbol_name"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Symbol Value:</label>
            <input
              className="form-control"
              name="symbol_value"
              type="number"
              placeholder="Symbol Value..."
              value={TradeJournalOption["symbol_value"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Type:</label>            
              <select
                  className="form-select"
                  name="type"
                  value={TradeJournalOption["type"]}
                  onChange={handleEventChange}
                  required
                >
              <option value="ce">Pe</option>
              <option value="pe">Ce</option>
              <option value="equity">Equity</option>
              <option value="future">Future</option>
              <option value="commodity">Commodity</option>
            </select>
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Buy/Sell:</label>
            <select
                  className="form-select"
                  name="buy_sell"
                  value={TradeJournalOption["buy_sell"]}
                  onChange={handleEventChange}
                  required
                >
              <option value="Buy">Buy</option>
              <option value="Sell">Sell</option> 
            </select>
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Intraday/CNC:</label>
            <select
                  className="form-select"
                  name="intraday"
                  value={TradeJournalOption["intraday"]}
                  onChange={handleEventChange}
                  required
                >
              <option value="Intraday">Intraday</option>
              <option value="CNC">CNC</option> 
            </select>
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Conviction Type:</label>
            <select
                  className="form-select"
                  name="conviction_type"
                  value={TradeJournalOption["conviction_type"]}
                  onChange={handleEventChange}
                  required
                >
              <option value="FullDay">Full Day</option>
              <option value="Instant">Instant</option> 
            </select>
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>AI Analysis Result %:</label>
            {/* <input
              className="form-control"
              name="analysis_result"
              type="number"
              placeholder="result..."
              value={TradeJournalOption["analysis_result"]}
              onChange={handleEventChange}
              required
            /> */}
            <select
                  className="form-select"
                  name="analysis_result"
                  value={TradeJournalOption["analysis_result"]}
                  onChange={handleEventChange}
                  required
                >
              <option value="0">0</option>
              <option value="25">25</option> 
              <option value="50">50</option> 
              <option value="75">75</option> 
              <option value="100">100</option>
            </select>
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Personnel Conviction:</label>
            <input
              className="form-control"
              type="number"
              name="personnel_conviction"
              placeholder="..."
              value={TradeJournalOption["personnel_conviction"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Reason for the Trade:</label>
            <input
              className="form-control"
              type="text"
              name="reason_for_trade"
              placeholder="reason..."
              value={TradeJournalOption["reason_for_trade"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Quantity:</label>
            <input
              className="form-control"
              type="number"
              name="quantity"
              placeholder="qty..."
              value={TradeJournalOption["quantity"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Trade Nature:</label>
            <select
              className="form-select"
              name="trade_nature"
              value={TradeJournalOption["trade_nature"]}
              onChange={handleEventChange}
              required         
            >
              <option value="normal">Normal</option>
              <option value="averaging">Averaging</option>
              <option value="scalingup">Scaling Up</option>
            </select>
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Entry Average:</label>
            <input
              className="form-control"
              type="number"
              name="entry_average"
              placeholder="avg..."
              value={TradeJournalOption["entry_average"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>First Entry Time:</label>
            <input
              className="form-control"
              name="first_entry_time"
              type="time"
              value={TradeJournalOption["first_entry_time"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Max stop Loss:</label>
            <input
              className="form-control"
              type="number"
              name="max_stop_loss"
              placeholder="loss..."
              value={TradeJournalOption["max_stop_loss"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Exp target:</label>
            <input
              className="form-control"
              type="number"
              name="exp_target"
              placeholder="..."
              value={TradeJournalOption["exp_target"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Exit price:</label>
            <input
              className="form-control"
              type="number"
              name="exit_price"
              placeholder="price..."
              value={TradeJournalOption["exit_price"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Exit Nature:</label>
            <input
              className="form-control"
              type="text"
              name="exit_nature"
              placeholder="Nature..."
              value={TradeJournalOption["exit_nature"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Exit Time:</label>
            <input
              className="form-control"
              name="exit_time"
              type="time"
              value={TradeJournalOption["exit_time"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Exit Reason:</label>
            <input
              className="form-control"
              type="text"
              name="exit_reason"
              placeholder="reason..."
              value={TradeJournalOption["exit_reason"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Max Loss seen:</label>
            <input
              className="form-control"
              type="number"
              name="max_loss_seen"
              placeholder="loss..."
              value={TradeJournalOption["max_loss_seen"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Max Profit seen:</label>
            <input
              className="form-control"
              type="number"
              name="max_profit_seen"
              placeholder="profit..."
              value={TradeJournalOption["max_profit_seen"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Highest Loss seen After Exit:</label>
            <input
              className="form-control"
              type="number"
              name="highest_loss_seen_afterexit"
              placeholder="loss..."
              value={TradeJournalOption["highest_loss_seen_afterexit"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Missed Profit After Exit:</label>
            <input
              className="form-control"
              type="number"
              name="missed_profit_afterexit"
              placeholder="profit..."
              value={TradeJournalOption["missed_profit_afterexit"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Satisfaction Grade:</label>
            {/* <input
              className="form-control"
              type="number"
              name="satisfaction_grade"
              placeholder="grade..."
              value={TradeJournalOption["satisfaction_grade"]}
              onChange={handleEventChange}
              required
            /> */}
            <select
                  className="form-select"
                  name="satisfaction_grade"
                  value={TradeJournalOption["satisfaction_grade"] || "0"}
                  onChange={handleEventChange}
                  required
                >
              <option value="-100">-100</option>
              <option value="-75">-75</option>
              <option value="-50">-50</option>
              <option value="-25">-25</option>
              <option value="0">0</option>
              <option value="25">25</option>
              <option value="50">50</option> 
              <option value="75">75</option> 
              <option value="100">100</option>
            </select>
          </div>

          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>Learning From Trade:</label>
            <textarea
              className="form-control"
              // type="text"
              name="learning_from_trade"
              placeholder="trade..."
              value={TradeJournalOption["learning_from_trade"]}
              onChange={handleEventChange}
              required              
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>P/L pts:</label>
            <input
              className="form-control"
              type="number"
              name="PLpts"
              placeholder="pts..."
              value={TradeJournalOption["PLpts"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>P/L Amt:</label>
            <input
              className="form-control"
              type="number"
              name="PLamt"
              placeholder="Amt..."
              value={TradeJournalOption["PLamt"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col" style={boxdivStyle}>
            <label  style={{ whiteSpace: 'nowrap' }}>P/L %:</label>
            <input
              className="form-control"
              type="number"
              name="PLpercentage"
              placeholder="%..."
              value={TradeJournalOption["PLpercentage"]}
              onChange={handleEventChange}
              required
            />
          </div> 
          <div>
          {loading ? (
            <Spinner />
          ) : (
            <button
              type="submit"
              className="btn btn-success btn-success-hover-rounded"
              style={{ width: 100}}
            >
              Save
            </button>
          )}
          </div>
        </div>
      </form>

     
      <br />
      <div className=" row">
        <div className="col">
          <div style={{ height: 600, backgroundColor: "lightblue" }}>
            {loading ? (
              <Spinner />
            ) : (
              <DataGrid
                rows={rowsTable}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                sortModel={[
                    {
                      field: 'id',
                      sort: 'desc',
                    },
                  ]}
              />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <div className="modal-header">
          <h2>Edit</h2>
          <span className="modal-close" onClick={handleCloseModal}>
            <CloseIcon />
          </span>
        </div>
        <div className="container-fluid">
          <br />
          <br />
          {EventToEdit && (
            <form onSubmit={editFormSubmit}>
              <div className="row ">
                <div className="row">
                  <div className="col-6">
                    <h4 className="text-dark">Event Date</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="date"
                      name="date"
                      value={EventToEdit["date"]}
                      onChange={handleEdittChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Event Time</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="time"
                      type="time"
                      value={EventToEdit["time"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Event Name</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="event"
                      type="text"
                      placeholder="Event..."
                      value={EventToEdit["event"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Actual Value</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="actual"
                      type="number"
                      placeholder="Actual Value..."
                      value={EventToEdit["actual"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Event DateTime</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="datetime"
                      type="datetime-local"
                      value={EventToEdit["datetime"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Event Forecast</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="forcast"
                      type="number"
                      placeholder="Forcast... "
                      value={EventToEdit["forcast"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Event Prior</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="prior"
                      type="number"
                      placeholder="Priour..."
                      value={EventToEdit["prior"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="row px-2 py-2">
                  <div className="col-6"></div>
                  <div className="col-6">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ width: 60 }}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default TradeJournnel;
