import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineGraph = ({ data1, data2, data3, data4, labels }) => {
  const chartRef = useRef();
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current !== null) {
      // Destroy the previous chart instance
      chartInstance.current.destroy();
    }

    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: { 
          labels: labels,
          datasets: [
            {
              label: 'nifty CE',
              data: data1,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.2,
              pointLabel: {
                display: true // Show point values
              }
            },
            {
              label: 'nifty PE',
              data: data2.map(value => -value), 
              fill: false,
              borderColor: 'rgb(192, 75, 192)',
              tension: 0.2
            },
            {
              label: 'bnf CE',
              data: data3,
              fill: false,
              borderColor: 'red',
              tension: 0.2
            },
            {
              label: 'bnf PE',
              data: data4.map(value => -value), 
              fill: false,
              borderColor: 'orange',
              tension: 0.2
            }
          ]
        }
      });
    }

    // Cleanup function
    return () => {
      if (chartInstance.current !== null) {
        // Destroy the chart instance when the component unmounts
        chartInstance.current.destroy();
      }
    };
  }, [data1, data2, data3, data4, labels]);

  return <canvas ref={chartRef} />;
};

export default LineGraph;
