import React from 'react';
import './Spinner.css'; // Import your CSS file for styling

// SpinnerOverlay component
const SpinnerOverlay = ({ isLoading }) => {
    return (
        <div className={`spinner-overlay ${isLoading ? 'visible' : 'hidden'}`}>
            {/* <div className="spinner"></div> */}
            <div className="spinner-container">
    <div className="spinner"></div>
</div>
            {/* <span className="loader"></span> */}
        </div>
    );
}

export default SpinnerOverlay;

 