import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/Modal";
import CloseIcon from "@mui/icons-material/Cancel";

function IndianEventCalender() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  const [selectEventDate, setselectEventDate] = useState(formattedDate); 
  const [loading, setLoading] = useState(true);
  const [rowsTable, setrowsTable] = useState([]); 
  const [isOpen, setIsOpen] = useState(false);
  const [EventCalederOption, setEventCalederOption] = useState({
    eventdate: selectEventDate,
    eventtime: formattedTime,
    eventname: "",
    eventactual: "",
    eventdatetime: formattedDateTime,
    eventforcast: "",
    eventprior: "",
  });
  const [EventToEdit, setEventToEdit] = useState(null); 

  useEffect(() => {
    tableDatFetch();
  }, []);
  const tableDatFetch = () => {
    setLoading(true);
    axios
      .get("/ind-economy-event-table")
      .then((response) => {
        setrowsTable(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!selectEventDate) return;
    setLoading(true);
    axios
      .post("/ind-economy-event-check-date", {
        date: selectEventDate,
      })
      .then((response) => {
        if (response.data.status === "YES") {
          
          setEventCalederOption(response.data.items);
        } else {
         
          setEventCalederOption({
            eventdate: selectEventDate,
            eventtime: formattedTime,
            eventname: "",
            eventactual: "",
            eventdatetime: formattedDateTime,
            eventforcast: "",
            eventprior: "",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectEventDate]);

  const handleEventChange = (event) => {
    const { name, value } = event.target;
    if (name === "eventdate") {
      setselectEventDate(value);
    }
    setEventCalederOption((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEdittChange = (event) => {
    const { name, value } = event.target;
     
    setEventToEdit((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const eventFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const apiUrl = "/ind-economy-event-create";
    axios({
      method: "post",
      url: apiUrl,
      data: EventCalederOption,
    })
      .then((response) => {
        if (response.data.status === "YES") {
          tableDatFetch();
        }
        setLoading(false);
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess("Wrong Data Entered");
        setLoading(false);
      });
  };
  const editFormSubmit = (event) => {
    event.preventDefault(); 
    const id=EventToEdit["id"];
    const apiUrl = `/ind-economy-event-Edit/${id}`;
    axios({
      method: "put",
      url: apiUrl,
      data: EventToEdit,
    })
      .then((response) => {  
        // tableDatFetch();
        const updatedRows = rowsTable.map(row =>
          row.id === id ? { ...row, ...EventToEdit } : row
        );
        setrowsTable(updatedRows);
        
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess("Wrong Data Entered");
        setLoading(false);
      });
  };
  

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      headerClassName: "custom-header",
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      headerClassName: "custom-header",
    },
    {
      field: "time",
      headerName: "Time",
      width: 100,
      headerClassName: "custom-header",
    },
    {
      field: "event",
      headerName: "Event",
      width: 150,
      headerClassName: "custom-header",
    },
    {
      field: "actual",
      headerName: "Actual",
      width: 150,
      headerClassName: "custom-header",
    },
    {
      field: "datetime",
      headerName: "Date Time",
      width: 150,
      headerClassName: "custom-header",
    },
    {
      field: "forcast",
      headerName: "Forcast",
      width: 70,
      headerClassName: "custom-header",
    },
    {
      field: "prior",
      headerName: "Prior",
      width: 70,
      headerClassName: "custom-header",
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 70,
      renderCell: (params) => (
        <button onClick={() => handleEditClick(params.row.id)}>Edit</button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 70,
      renderCell: (params) => (
        <button onClick={() => handleDeleteClick(params.row.id)}>Delete</button>
      ),
    },
  ];
  const notifySuccess = (data) => {
    if (typeof data === "string" && data.toLowerCase() === "error value") {
      toast.error("Wrong Data Entered");
    } else {
      toast.success(data);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setEventToEdit(null); 
  };
  const handleEditClick = (id) => {
    const event = rowsTable.find((row) => row.id === id); 
    setEventToEdit(event); 
    setIsOpen(true);
  }; 
  const handleDeleteClick = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) { 
      setLoading(true);
        axios.delete(`/ind-economy-event/${id}`)
            .then((response) => {
                setrowsTable(rowsTable.filter(row => row.id !== id)); 
                // tableDatFetch();  
                toast.success(response.data.message);
            })
            .catch((error) => {
                console.error("Error deleting event:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
      toast.success("Deletion cancelled.");
    }
  };
  return (
    <div className="container-fluid ">
      <h3>Indian Economy Event Calender</h3>
      <ToastContainer  autoClose={1000} />
      <form onSubmit={eventFormSubmit}>
        <div className="row">
          <div className="col">
            <label>Date:</label>
            <input
              className="form-control"
              type="date"
              name="eventdate"
              value={EventCalederOption["eventdate"] || selectEventDate}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col">
            <label>Time:</label>
            <input
              className="form-control"
              name="eventtime"
              type="time"
              value={EventCalederOption["eventtime"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col">
            <label>Event Name:</label>
            <input
              className="form-control"
              name="eventname"
              type="text"
              placeholder="Event..."
              value={EventCalederOption["eventname"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col">
          <label>Actual Value:</label>
            <input
              className="form-control"
              name="eventactual"
              type="number"
              placeholder="Actual value..."
              value={EventCalederOption["eventactual"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col">
            <label>Date Time:</label>
            <input
              className="form-control"
              name="eventdatetime"
              type="datetime-local"
              value={EventCalederOption["eventdatetime"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col">
          <label>Forcast:</label>
            <input
              className="form-control"
              name="eventforcast"
              type="number"
              placeholder="Forcast... "
              value={EventCalederOption["eventforcast"]}
              onChange={handleEventChange}
              required
            />
          </div>
          <div className="col">
          <label>Priour:</label>
            <input
              className="form-control"
              name="eventprior"
              type="number"
              placeholder="Priour..."
              value={EventCalederOption["eventprior"]}
              onChange={handleEventChange}
              required
            />
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button
              type="submit"
              className="btn btn-success"
              style={{ width: 60 }}
            >
              Save
            </button>
          )}
        </div>
      </form>
      <br />
      <div className=" row">
        <div className="col">
          <div style={{ height: 600, backgroundColor: "lightblue" }}>
            {loading ? (
              <Spinner />
            ) : (
              <DataGrid
                rows={rowsTable}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
              />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <div className="modal-header">
          <h2>Edit</h2>
          <span className="modal-close" onClick={handleCloseModal}>
            <CloseIcon />
          </span>
        </div>
        <div className="container-fluid">
          <br />
          <br />
          {EventToEdit && ( 
          <form onSubmit={editFormSubmit}  >
            <div className="row ">
              <div className="row">
                <div className="col-6">
                  <h4 className="text-dark">Event Date</h4>
                </div>
                <div className="col-6">
                  <input
                    className="form-control"
                    type="date"
                    name="date"
                    value={EventToEdit["date"]}
                    onChange={handleEdittChange}
                    disabled
                  />
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="col-6">
                <h4 className="text-dark">Event Time</h4>
                </div>
                <div className="col-6">
                  <input
                    className="form-control"
                    name="time"
                    type="time"
                    value={EventToEdit["time"]}
                    onChange={handleEdittChange}
                    required
                  />
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="col-6"><h4 className="text-dark">Event Name</h4></div>
                <div className="col-6">
                  <input
                    className="form-control"
                    name="event"
                    type="text"
                    placeholder="Event..."
                    value={EventToEdit["event"]}
                    onChange={handleEdittChange}
                    required
                  />
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="col-6"><h4 className="text-dark">Actual Value</h4></div>
                <div className="col-6">
                  <input
                    className="form-control"
                    name="actual"
                    type="number"
                    placeholder="Actual Value..."
                    value={EventToEdit["actual"]}
                    onChange={handleEdittChange}
                    required
                  />
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="col-6"><h4 className="text-dark">Event DateTime</h4></div>
                <div className="col-6">
                  <input
                    className="form-control"
                    name="datetime"
                    type="datetime-local"
                    value={EventToEdit["datetime"]}
                    onChange={handleEdittChange}
                    required
                  />
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="col-6"><h4 className="text-dark">Event Forecast</h4></div>
                <div className="col-6">
                  <input
                    className="form-control"
                    name="forcast"
                    type="number"
                    placeholder="Forcast... "
                    value={EventToEdit["forcast"]}
                    onChange={handleEdittChange}
                    required
                  />
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="col-6"><h4 className="text-dark">Event Prior</h4></div>
                <div className="col-6">
                  <input
                    className="form-control"
                    name="prior"
                    type="number"
                    placeholder="Priour..."
                    value={EventToEdit["prior"]}
                    onChange={handleEdittChange}
                    required
                  />
                </div>
              </div>
              <br />
              <br />
              <div className="row px-2 py-2">
                <div className="col-6"></div>
                <div className="col-6">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ width: 60 }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default IndianEventCalender;
