import React from 'react'

function IndianMarketADRcard({datas,handleStockChange}) {
  return (
    <div className="card flex-fill border-0 illustration">
      <div
        className="card-body p-0 d-flex flex-fill"
        style={{ minWidth: "150px" }}
      >
        <div className="row g-0 w-100 card_color">
          <div
            className="card-header"
            dangerouslySetInnerHTML={{ __html: datas.stocks }}
          />
       {/* {
             loading ? <Spinner /> : */}
          <div className="card-body text-center">
          <div
                  className="card-body text-center"
                  style={{ width: "100%" }}
                >
                  <input
                    type="number"
                    className="form-control"
                    step="0.01"
                    name={datas.stocks}
                    value={datas.adr_value} 
                    onChange={(e) => handleStockChange(e, datas.stocks)} 
                    required
                  />
                </div>
          </div>
          {/* } */}
        </div>
      </div>
    </div>
  )
}

export default IndianMarketADRcard