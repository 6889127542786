import React  from "react";
import Spinner from '../components/Spinner'

function M1Entry({
  cardContent,
  handleOptionChange,
  selectedOption,
  DateRequired,
  currentDateTime2,
  handleDateTimeChange,
  raw2col3btnradio3_date,
  raw3col1btnradio1_date,
  raw3col2btnradio2_date,
  loading,
  
}) {   
 

  return (
    <div className="card flex-fill border-0 illustration">
      <div
        className="card-body p-0 d-flex flex-fill"
        style={{ minWidth: "150px" }}
      >
        <div className="row g-0 w-100 card_color">
          <div
            className="card-header"
            dangerouslySetInnerHTML={{ __html: cardContent.header }}
          />
          {
            loading ? <Spinner /> :
          <div className="card-body text-center">
            <div
              className="btn-group text-center border"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name={cardContent.name}
                id={`${cardContent.name}_YES`}
                autoComplete="off"
                value="YES"
                onChange={() => handleOptionChange(cardContent.name, "YES")} // Pass both name and value
                checked={selectedOption === "YES"}
              />
              <label
                className="btn btn-outline-success"
                htmlFor={`${cardContent.name}_YES`}
              >
                YES
              </label>
              <input
                type="radio"
                className="btn-check"
                name={cardContent.name}
                id={`${cardContent.name}_NO`}
                autoComplete="off"
                value="NO"
                onChange={() => handleOptionChange(cardContent.name, "NO")} // Pass both name and value
                checked={selectedOption === "NO"}
              />
              <label
                className="btn btn-outline-danger"
                htmlFor={`${cardContent.name}_NO`}
              >
                NO
              </label>
            </div>
            
            <div> 
             {DateRequired && DateRequired.needDate === "yes" && (
                <div className="input-group date" id="datetimepickerM1" style={{paddingTop: '5px'}}>
                  <input
                    type="datetime-local"
                    className="form-control"
                    name={`${cardContent.name}_date`}
                    value={
                      cardContent.name === 'raw2col3btnradio3' ? raw2col3btnradio3_date :
                      cardContent.name === 'raw3col1btnradio1' ? raw3col1btnradio1_date :
                      cardContent.name === 'raw3col2btnradio2' ? raw3col2btnradio2_date :
                      currentDateTime2
                    }
                    onChange={(e) => handleDateTimeChange(e.target.name, e.target.value)}
                    // onChange=  {handleDateTimeChange} 
                
                        
                  />
                </div>
              )}
            </div>
          </div>

          }
        </div>
      </div>
    </div>
  );
}

export default M1Entry;
