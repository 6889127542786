import React, { useState, useEffect } from "react";
import LineGraph from "../charts/LineGraph";
import "../charts/ModalFullScreen.css";
import getCurrentDate from "../components/currentDate";
import axios from "./axios";
import Spinner from "../components/Spinner";

function Home() {
  const [loading, setLoading] = useState(true);
  const [loadingCalender, setLoadingCalender] = useState(true);
  const currentDate = getCurrentDate();
  const [todayDate, setTodayDate] = useState(currentDate);
  const [ncoValues, setNcoValues] = useState([]);
  const [calenderIndianEconomy, setCalenderIndianEconomy] = useState([]);
  const [calenderIndianMarket, setCalenderIndianMarket] = useState([]);
  const [calenderUsEconomy, setCalenderUsEconomy] = useState([]);
  const [calenderUsMarket, setCalenderUsMarket] = useState([]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    if (newDate !== todayDate) {
      setTodayDate(newDate);
    }
  }; 
  useEffect(() => {
    setLoadingCalender(true);
    axios
      .post("/getDashBoard", {
        date: todayDate,
      })
      .then((response) => {  
        setCalenderIndianEconomy(response.data.calenderData.IndianEconomyEventCalender);
        setCalenderIndianMarket(response.data.calenderData.IndianMarketHolidayCalender);
        setCalenderUsEconomy(response.data.calenderData.UsEconomyEventCalender);
        setCalenderUsMarket(response.data.calenderData.UsMarketHolidayCalender);
        setLoadingCalender(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [todayDate]);

  useEffect(() => {
    setLoading(true);
    axios
      .post("/getDashBoardNco", {
        date: todayDate,
      })
      .then((response) => {
        setNcoValues(response.data.ncovalues);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [todayDate]);

  // -----convert yyyy-mm-dd-- to -- dd-mm-yyyy----
  function formatDate(dateString) {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  
  let niftycePriceValues = [];
  let niftypePriceValues = [];
  let bnfcePriceValues = [];
  let bnfpePriceValues = [];

  if (ncoValues && ncoValues.niftyce) {
    const niftyce = ncoValues.niftyce;
    niftycePriceValues = Array.from({ length: 5 }, (_, index) => {
      const item = niftyce.find(
        (entry) => entry.nco_nifty_ce === `nco${index}`
      );
      if (item) {
        return parseInt(item.nifty_ce_price_value);
      } else {
        return undefined; // or null if you prefer null for missing values
      }
    });
  }
  if (ncoValues && ncoValues.niftype) {
    const niftype = ncoValues.niftype;
    niftypePriceValues = Array.from({ length: 5 }, (_, index) => {
      const item = niftype.find(
        (entry) => entry.nco_nifty_pe === `nco${index}`
      );
      if (item) {
        return parseInt(item.nifty_pe_price_value);
      } else {
        return undefined; // or null if you prefer null for missing values
      }
    });
  }
  if (ncoValues && ncoValues.bnfce) {
    const bnfce = ncoValues.bnfce;
    bnfcePriceValues = Array.from({ length: 5 }, (_, index) => {
      const item = bnfce.find((entry) => entry.nco_bnf_ce === `nco${index}`);
      if (item) {
        return parseInt(item.bnf_ce_price_value);
      } else {
        return undefined; // or null if you prefer null for missing values
      }
    });
  }
  if (ncoValues && ncoValues.bnfpe) {
    const bnfpe = ncoValues.bnfpe;
    bnfpePriceValues = Array.from({ length: 5 }, (_, index) => {
      const item = bnfpe.find((entry) => entry.nco_bnf_pe === `nco${index}`);
      if (item) {
        return parseInt(item.bnf_pe_price_value);
      } else {
        return undefined; // or null if you prefer null for missing values
      }
    });
  }

  const data1 = niftycePriceValues;
  const data2 = niftypePriceValues;
  const data3 = bnfcePriceValues;
  const data4 = bnfpePriceValues;

  const labels = ["nco", "nco+1", "nco+2", "nco+3", "nco+4"];

  return (
    <div className="container font-italic">
      <h5>
        <span style={{ color: "lime" }}>DASHBOARD </span>
        <input
          id="selectdate_dash"
          value={todayDate}
          onChange={handleDateChange}
          type="date"
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
            color: "lime",
          }}
          // max={currentDate}
        />
      </h5>
      { loadingCalender && <Spinner /> }
      <div className="row justify-content-center mt-3">
        <div className="row">
          <div className="col-lg-3 col-md-6 mt-2.">
            <div className="card-body card_color card_border_curve p-2">
              <h5 className="card-title" style={{ color: "#00CCCC" }}>
                Indian Economy Event Calender
              </h5>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p className="font-weight-bold mb-1">Event Name : {calenderIndianEconomy.eventname}</p>
                    <p className="font-weight-bold mb-1">Actual Value : {calenderIndianEconomy.eventactual}</p>
                    <p className="font-weight-bold mb-1">Forecast : {calenderIndianEconomy.eventforcast}</p>
                    <p className="font-weight-bold mb-1">Prior : {calenderIndianEconomy.eventprior}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-2.">
            <div className="card-body card_color card_border_curve2 p-2">
              <h5 className="card-title" style={{ color: "#C933F7" }}>
                Indian Market Holiday Calender
              </h5>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p className="font-weight-bold mb-1">Holiday : {calenderIndianMarket.holiday}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-2.">
            <div className="card-body card_color card_border_curve p-2">
              <h5 className="card-title" style={{ color: "#E1FF01" }}>
                US Economy Event Calender
              </h5>
              <div className="container">
                <div className="row">
                  <div className="col">
                  <p className="font-weight-bold mb-1">Event Name : {calenderUsEconomy.eventname}</p>
                    <p className="font-weight-bold mb-1">Actual Value : {calenderUsEconomy.eventactual}</p>
                    <p className="font-weight-bold mb-1">Forecast : {calenderUsEconomy.eventforcast}</p>
                    <p className="font-weight-bold mb-1">Prior : {calenderUsEconomy.eventprior}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-2.">
            <div className="card-body card_color card_border_curve2 p-2">
              <h5 className="card-title" style={{ color: "#23FB04" }}>
                US Market Holiday Calender
              </h5>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p className="font-weight-bold mb-1">Holiday : {calenderUsMarket.holiday}</p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6">
            <div className="card-body card_color">
              <h5 className="card-title">NCO GRAPH</h5>
              {loading ? (
                <Spinner />
              ) : (
                <LineGraph
                  data1={data1 || []}
                  data2={data2 || []}
                  data3={data3 || []}
                  data4={data4 || []}
                  labels={labels}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            {loading ? (
              <Spinner />
            ) : (
              <table className="table table-striped table-bordered border border-round  border-secondary">
                <thead>
                  <tr>
                    <th>NCO</th>
                    <th>NCO Date</th>
                    <th>Price Value</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(ncoValues).map((key, index) => (
                    <React.Fragment key={index}>
                      {ncoValues[key].map((item, nestedIndex) => (
                        <tr key={`${index}-${nestedIndex}`}>
                          <td>
                            {key +
                              "_" +
                              (item.nco_nifty_ce ||
                                item.nco_nifty_pe ||
                                item.nco_bnf_ce ||
                                item.nco_bnf_pe)}
                          </td>

                          <td>
                            {formatDate(
                              item.nifty_ce_date ||
                                item.nifty_pe_date ||
                                item.bnf_ce_date ||
                                item.bnf_pe_date
                            )}
                          </td>
                          <td>
                            {item.nifty_ce_price_value ||
                              item.nifty_pe_price_value ||
                              item.bnf_ce_price_value ||
                              item.bnf_pe_price_value}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
