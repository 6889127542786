import React, { useState, useEffect } from "react";
// import axios from "axios";
import DataEntryRoute from "../components/DataEntryRoute";
import ChartImg from "../charts/ChartImg";
import NcopageMain from "../ncoPage/NcopageMain";
import TradeJournnel from "./TradeJournnel";

 

function DataEntry() {
  const [selectedDate, setSelectedDate] = useState("");
  const [currentDateTime, setcurrentDateTime] = useState("");
  const [currentTime, setcurrentTime] = useState("");
  const [maxsetDate, setmaxsetDate] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(null);

   const handleAccordionClick = (index) => {
    if (accordionOpen === index) {
      // If the clicked item is already open, close it
      setAccordionOpen(null);
    } else {
      // Otherwise, open the clicked item
      setAccordionOpen(index);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    setmaxsetDate(formattedDate);
    setSelectedDate(formattedDate);
    setcurrentDateTime(formattedDateTime);
    setcurrentTime(formattedTime);
  }, []);
  // ----------never pall null value-----------------------------------/////////
  if (!selectedDate) return; 

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    if (newDate !== selectedDate) {
      setSelectedDate(newDate);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container-fluid">
      {/* ------------navigation end-------------------------- */}
      <div className="container-fluid rounded border">
        <div className="row border align-items-center ">
          <div
            className="col-sm-3 text-center   rounded"
            style={{ padding: "10px" }}
          >
            <h4 className="shadow-lg p-2 mb-2 rounded ">Date</h4>
          </div>
          <div className="col-sm-3">
            <div
              className="input-group date shadow-lg p-2 mb-2 "
              // id="startDate"
              style={{ width: "300px", padding: "10px" }}
            >
              <input
                id="startDate"
                value={selectedDate}
                onChange={handleDateChange}
                className="form-control text-center"
                type="date"
                max={maxsetDate}
              />
            </div>
          </div>
          <div className="col-sm-3"></div>
          <div className="col-sm-3"></div>
        </div>
      </div>
      {/* ------------Today date--------------------------------   */}
      {/* ------------main body--------- */}

      <div > 
        <div className="accordion" id="accordionExample">
          {/* First Accordion Item */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                onClick={() => handleAccordionClick(1)}
              >
               Market Opening
                
              </button>
            </h2>
            <div
              className={
                "accordion-collapse collapse" +
                (accordionOpen === 1 ? " show" : "")
              }
            >
              <div className="accordion-body">
              {accordionOpen === 1 &&
              <DataEntryRoute selectedDate={selectedDate} currentTime={currentTime} currentDateTime={currentDateTime} />
              }
              </div>
            </div>
          </div>
          
          {/* Second Accordion Item */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                onClick={() => handleAccordionClick(2)}
              >
                NCO
              </button>
            </h2>
            <div
              className={
                "accordion-collapse collapse" +
                (accordionOpen === 2 ? " show" : "")
              }
            >
              <div className="accordion-body">
              {accordionOpen === 2 &&
              <NcopageMain selectedDate={selectedDate}/>
              }
              </div>
            </div>
          </div>
          {/* Third Accordion Item */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                onClick={() => handleAccordionClick(3)}
              >
                Chart
              </button>
            </h2>
            <div
              className={
                "accordion-collapse collapse" +
                (accordionOpen === 3 ? " show" : "")
              }
            >
              <div className="accordion-body">
              {accordionOpen === 3 &&
               <ChartImg  selectedDate={selectedDate} />
              }
              </div>
            </div>
          </div>
         {/* 4th Accordion Item */}
         <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                onClick={() => handleAccordionClick(4)}
              >
                Trade Journnel
              </button>
            </h2>
            <div
              className={
                "accordion-collapse collapse" +
                (accordionOpen === 4 ? " show" : "")
              }
            >
              <div className="accordion-body">
              {accordionOpen === 4 &&
               <TradeJournnel  selectedDate={selectedDate} />
              }
              </div>
            </div>
          </div>

        </div>
    

        {/* <Routes>
          <Route
            path="/dataentry/*"
            element={
              <Outlet>
               
                <Route
                    path="dataentry-page"
                    element={<DataEntryRoute selectedDate={selectedDate} currentTime={currentTime} currentDateTime={currentDateTime} />}
                  />
                <Route path="ncomainpage" element={<Ncopage />} />
              </Outlet>
            }
          />
        </Routes> */}
      </div>

      <div>
        <button
          onClick={handleScrollToTop}
          className="btn  rounded-circle scroll-to-bottom-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-up"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default DataEntry;
