import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function IndianHolidayCalender() {
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    setselectHoliday(formattedDate);
  }, []);

 
  const [selectHoliday, setselectHoliday] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState(false);
  // const [indianHoliday, setindianHoliday] = useState([]);
  const [indianHolidayText, setindianHolidayText] = useState("");
  const [rowsTable,setrowsTable] = useState([]);

  const handleHolidayChange = (e) => {
    const newDate = e.target.value;
    if (newDate !== selectHoliday) {
      setselectHoliday(newDate);
    }
  };

  useEffect(() => {
    tableDatFetch();
  }, []);
  const tableDatFetch=()=>{
    setLoading(true);
    axios
      .get("/indianHolidayCalender-table")
      .then((response) => { 
      setrowsTable(response.data) 
      setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    if (!selectHoliday) return; 
    setLoading(true);
    axios
      .post("/indianHolidayCalender-check-date", {
        date: selectHoliday,
      })
      .then((response) => {
        if(response.data.status === 'YES'){
        setDataExist(true)
        }else{
          setDataExist(false)
        }
        setindianHolidayText(response.data.items.holiday);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectHoliday]);

  const handleHolidayText = (e) => {
    const value = e.target.value;
    setindianHolidayText(value);
  };

  const HolidaySubmit = (event) => {
  
    event.preventDefault();
    const data = {
      holidaytext:indianHolidayText,
      dateFind: selectHoliday,
    }; 
    setLoading(true);
    const apiUrl =dataExist 
        ? `/indianHolidayCalender-Edit/${selectHoliday}`
        : "/indianHolidayCalender-create";
    axios({
      method: dataExist  ? "put" : "post",
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        if(response.data.status === 'YES'){
          setDataExist(true)
          tableDatFetch();
          }else{
            setDataExist(false)
          }
        setLoading(false);
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess('Wrong Data Entered');
        setLoading(false);
      });
  };
  const notifySuccess = (data) => {
    if (typeof data === 'string' && data.toLowerCase() === 'error value') {
      toast.error('Wrong Data Entered');
  } else {
      toast.success(data);
  }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: 'custom-header' },
    { field: "date", headerName: "Date", width: 150, headerClassName: 'custom-header' },
    { field: "holiday", headerName: "Holiday", width: 150, headerClassName: 'custom-header' }
  ];
 
  return (
    <div className="container-fluid ">
      <h3>Indian Market Holiday Calender</h3>
      <ToastContainer autoClose={1000} />
      <div className="row">
        <div className="col-md-5 d-flex justify-content-center align-items-center">
          <div className="row text-center" style={{ width: "80%" }}>
            <form onSubmit={HolidaySubmit}>
              <input
                type="date"
                className="form-control"
                style={{ marginBottom: 10 }}
                value={selectHoliday}
                onChange={handleHolidayChange}
              />
              <textarea
                className="form-control"
                rows="2"
                style={{ marginBottom: 10 }}
                placeholder="Enter your text here..."
                onChange={handleHolidayText}
                value={indianHolidayText}
                required
              ></textarea>
              
            
              { loading ? <Spinner /> :   !dataExist ? (
                <button
                  type="submit"  
                  className="btn btn-success"
                  style={{ width: 200 }}
                >
                  Add
                </button>
              ) : (
                <button
                  type="submit"  
                  className="btn btn-primary"
                  style={{ width: 200 }}
                >
                  Edit
                </button>
              )}
            </form>
          </div>

          <div className="row"></div>
        </div>
        <div className="col-md-7">
          <div style={{ height: 600, backgroundColor: "lightblue" }}>
          {
            loading ? <Spinner /> :
            <DataGrid
              rows={rowsTable}
              columns={columns}
              checkboxSelection
            />
          }
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndianHolidayCalender;
