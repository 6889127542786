import React, { useState } from "react";
import axios from "axios";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/change-password", {
        current_password: currentPassword,
        new_password: newPassword,
      });
      setSuccessMessage(response.data.message);
      setCurrentPassword("");
      setNewPassword("");
      setError("");
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center mt-5">
      <div className="row">
        <div className="card" style={{ minWidth: "500PX", maxWidth: "700PX" }}>
          <div className="card-header">
            <h2>Change Password</h2>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="row mt-2">
                  <div className="col-6">
                    {" "}
                    <label htmlFor="currentPassword">Current Password:</label>
                  </div>
                  <div className="col-6">
                    <input
                      type="password"
                      className="form-control"
                      id="currentPassword"
                      name="currentPassword"
                      value={currentPassword}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row mt-2">
                  <div className="col-6">
                    <label htmlFor="newPassword">New Password:</label>
                  </div>
                  <div className="col-6">
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      value={newPassword}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-4">
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="row">
        {error && <div className="alert alert-danger">{error}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
