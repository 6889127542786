import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../auth/AuthUser";

function Navbar({ handleSidebarToggle }) {
  // const [currentTime, setCurrentTime] = useState(new Date());

  const {token,logout} = AuthUser();
  const logoutUser =()=>{
    if(token != undefined){
     logout();
    }
  }


  // useEffect(() => {
   
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div>
      <nav className="navbar navbar-expand px-3 border-bottom ">
        <button
          className="btn"
          id="sidebar-toggle"
          type="button"
          onClick={handleSidebarToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <h1>{currentTime.toLocaleTimeString()}</h1> */}
        <div className="navbar-collapse navbar">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <Link
                to="/"
                data-bs-toggle="dropdown"
                className="nav-icon pe-md-0"
              >
                <img
                  src="assets/image/profile.jpeg"
                  className="avatar img-fluid rounded"
                  alt=""
                />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="/profile" className="dropdown-item">
                  Profile
                </Link>
                <Link to="/" className="dropdown-item">
                  Setting
                </Link>
                {/* <Link to="/logout" className="dropdown-item">Logout</Link> */}
                <Link role="button" className="dropdown-item" onClick={logoutUser}>
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
