import React, { useState, useEffect } from "react";
import axios from "../pages/axios";
import "./filterelement.css";

const Checkbox = ({ id, name, checked, onChange }) => {
  return (
    <div className="checkbox-wrapper-31">
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        name={name}
        checked={checked}
        onChange={() => onChange(id)}
      />
      <svg viewBox="0 0 35.6 35.6">
        <circle className="background" cx="17.8" cy="17.8" r="17.8"></circle>
        <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
        <polyline
          className="check"
          points="11.78 18.12 15.55 22.23 25.17 12.87"
        ></polyline>
      </svg>
    </div>
  );
};

function FilterDataMainPage() {
  const [data, setData] = useState({ dataone: null, datatwo: null });
  const [entries, setEntries] = useState([
    { name: "1a", header: "dsfdf", check: false },
    { name: "2a", header: "dsfdf3", check: false },
    { name: "3a", header: "dsfdff", check: false },
    { name: "4a", header: "dsfdf7", check: false },
    { name: "5a", header: "dsfdf9", check: false },
    { name: "1a", header: "dsfdf", check: false },
    { name: "2a", header: "dsfdf3", check: false },
    { name: "3a", header: "dsfdff", check: false },
    { name: "4a", header: "dsfdf7", check: false },
    { name: "5a", header: "dsfdf9", check: false },
  ]);
  const [entries2, setEntries2] = useState([
    { name: "1a", header: "dsfdf", check: false },
    { name: "2a", header: "dsfdf3", check: false },
    { name: "3a", header: "dsfdff", check: false },
    { name: "4a", header: "dsfdf7", check: false },
    { name: "5a", header: "dsfdf9", check: false },
    { name: "1a", header: "dsfdf", check: false },
    { name: "2a", header: "dsfdf3", check: false },
    { name: "3a", header: "dsfdff", check: false },
    { name: "4a", header: "dsfdf7", check: false },
    { name: "5a", header: "dsfdf9", check: false },
  ]);
  const [selectAll, setSelectAll] = useState(false); // State for master checkbox for entries
  const [selectAll2, setSelectAll2] = useState(false); // State for master checkbox for entries2

  const handleCheckboxChange = (id, type) => {
    if (type === 'entries') {
      setEntries((prevEntries) =>
        prevEntries.map((entry, index) =>
          index === id ? { ...entry, check: !entry.check } : entry
        )
      );
    } else if (type === 'entries2') {
      setEntries2((prevEntries) =>
        prevEntries.map((entry, index) =>
          index === id ? { ...entry, check: !entry.check } : entry
        )
      );
    }
  };

  // Function to toggle all checkboxes in both arrays
  const toggleAllCheckboxes = (type) => {
    if (type === 'entries') {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
      setEntries((prevEntries) =>
        prevEntries.map((entry) => ({ ...entry, check: newSelectAll }))
      );
    } else if (type === 'entries2') {
      const newSelectAll2 = !selectAll2;
      setSelectAll2(newSelectAll2);
      setEntries2((prevEntries) =>
        prevEntries.map((entry) => ({ ...entry, check: newSelectAll2 }))
      );
    }
  };

  useEffect(() => {
    const fetchDataOne = async () => {
      try {
        const response = await axios.post("/filterdatafirst", {});
        // console.log(response.data);
        setData((prevData) => ({ ...prevData, dataone: response.data }));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchDataTwo = async () => {
      try {
        const response = await axios.post("/filterdatasecond", {});
        // console.log(response.data);
        setData((prevData) => ({ ...prevData, datatwo: response.data }));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchDataOne();
    fetchDataTwo();
  }, []);
console.log(entries2)
  return (
    <div className="container-fluid">
      <h1>FilterDataMainPage</h1>
      <div>{data.dataone}</div>
      <div className="row red-border">
        <div className="col-sm-6">
          <p className="fluorescent-green">Segment</p>
        </div>
        <div className="col-sm-6">
          {/* Master Checkbox for entries */}
          <label htmlFor="masterCheckbox" className="fluorescent-green">Select All</label>
          <input
            type="checkbox"
            id="masterCheckbox"
            checked={selectAll}
            onChange={() => toggleAllCheckboxes('entries')}
          />
        </div>
        {entries.map((entry, id) => (
          <div className="box_color" style={{ width: 200 }} key={id}>
            <div className="col-sm-10">
              <p dangerouslySetInnerHTML={{ __html: entry.header }}></p>
            </div>
            <div className="col-sm-2">
              <Checkbox
                id={id}
                name={entry.name}
                checked={entry.check}
                onChange={() => handleCheckboxChange(id, 'entries')}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="row blue-border">
        <div className="col-sm-6">
          <p className="fluorescent-green">Date Speciality</p>
        </div>
        <div className="col-sm-6">
          {/* Master Checkbox for entries2 */}
          <label htmlFor="masterCheckbox2" className="fluorescent-green">Select All</label>
          <input
            type="checkbox"
            id="masterCheckbox2"
            checked={selectAll2}
            onChange={() => toggleAllCheckboxes('entries2')}
          />
        </div>
        {entries2.map((entry, id) => (
          <div className="box_color" style={{ width: 200 }} key={id}>
            <div className="col-sm-10">
              <p dangerouslySetInnerHTML={{ __html: entry.header }}></p>
            </div>
            <div className="col-sm-2">
              <Checkbox
                id={id}
                name={entry.name}
                checked={entry.check}
                onChange={() => handleCheckboxChange(id, 'entries2')}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterDataMainPage;
