import React, { useState, useEffect } from "react";
import LineGraph from "../charts/LineGraph";
import "../charts/ModalFullScreen.css"; 
import axios from "../pages/axios";
import Spinner from "../components/Spinner";

function NcoAnalysisGraph({analysisDate}) {
    const [loading, setLoading] = useState(true); 
    const [ncoValues, setNcoValues] = useState([]); 
    const [expDay , setExpDay] = useState([]); 
    useEffect(() => {
        setLoading(true);
        axios
          .post("/getDashBoardNco", {
            date: analysisDate,
          })
          .then((response) => {
            setNcoValues(response.data.ncovalues);
            setExpDay(response.data.expiryday);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, [analysisDate]);
    
      // -----convert yyyy-mm-dd-- to -- dd-mm-yyyy----
      function formatDate(dateString) {
        const parts = dateString.split("-");
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    
      
      let niftycePriceValues = [];
      let niftypePriceValues = [];
      let bnfcePriceValues = [];
      let bnfpePriceValues = [];
    
      if (ncoValues && ncoValues.niftyce) {
        const niftyce = ncoValues.niftyce;
        niftycePriceValues = Array.from({ length: 5 }, (_, index) => {
          const item = niftyce.find(
            (entry) => entry.nco_nifty_ce === `nco${index}`
          );
          if (item) {
            return parseInt(item.nifty_ce_price_value);
          } else {
            return undefined; // or null if you prefer null for missing values
          }
        });
      }
      if (ncoValues && ncoValues.niftype) {
        const niftype = ncoValues.niftype;
        niftypePriceValues = Array.from({ length: 5 }, (_, index) => {
          const item = niftype.find(
            (entry) => entry.nco_nifty_pe === `nco${index}`
          );
          if (item) {
            return parseInt(item.nifty_pe_price_value);
          } else {
            return undefined; // or null if you prefer null for missing values
          }
        });
      }
      if (ncoValues && ncoValues.bnfce) {
        const bnfce = ncoValues.bnfce;
        bnfcePriceValues = Array.from({ length: 5 }, (_, index) => {
          const item = bnfce.find((entry) => entry.nco_bnf_ce === `nco${index}`);
          if (item) {
            return parseInt(item.bnf_ce_price_value);
          } else {
            return undefined; // or null if you prefer null for missing values
          }
        });
      }
      if (ncoValues && ncoValues.bnfpe) {
        const bnfpe = ncoValues.bnfpe;
        bnfpePriceValues = Array.from({ length: 5 }, (_, index) => {
          const item = bnfpe.find((entry) => entry.nco_bnf_pe === `nco${index}`);
          if (item) {
            return parseInt(item.bnf_pe_price_value);
          } else {
            return undefined; // or null if you prefer null for missing values
          }
        });
      }
    
      const data1 = niftycePriceValues;
      const data2 = niftypePriceValues;
      const data3 = bnfcePriceValues;
      const data4 = bnfpePriceValues;
    
      const labels = ["nco", "nco+1", "nco+2", "nco+3", "nco+4"];
  return (
    <div className="row mt-1">
    <div className="col-lg-6 col-md-6">
      <div className="card-body card_color">
        <h5 className="card-title">EXPIRY DAY : {expDay}</h5>
        {loading ? (
          <Spinner />
        ) : (
          <LineGraph
            data1={data1 || []}
            data2={data2 || []}
            data3={data3 || []}
            data4={data4 || []}
            labels={labels}
          />
        )}
      </div>
    </div>
    <div className="col-lg-6 col-md-6">
      {loading ? (
        <Spinner />
      ) : (
        <table className="table table-striped table-bordered border border-round  border-secondary">
          <thead>
            <tr>
              <th>NCO</th>
              <th>NCO Date</th>
              <th>Price Value</th>
            </tr>
          </thead>

          <tbody>
            {Object.keys(ncoValues).map((key, index) => (
              <React.Fragment key={index}>
                {ncoValues[key].map((item, nestedIndex) => (
                  <tr key={`${index}-${nestedIndex}`}>
                    <td>
                      {key +
                        "_" +
                        (item.nco_nifty_ce ||
                          item.nco_nifty_pe ||
                          item.nco_bnf_ce ||
                          item.nco_bnf_pe)}
                    </td>

                    <td>
                      {formatDate(
                        item.nifty_ce_date ||
                          item.nifty_pe_date ||
                          item.bnf_ce_date ||
                          item.bnf_pe_date
                      )}
                    </td>
                    <td>
                      {item.nifty_ce_price_value ||
                        item.nifty_pe_price_value ||
                        item.bnf_ce_price_value ||
                        item.bnf_pe_price_value}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  </div>
  )
}

export default NcoAnalysisGraph