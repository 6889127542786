import React, { useState, useEffect } from "react";
import M1Entry from "./M1Entry";
// import toast, { Toaster } from 'react-hot-toast';
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../components/Spinner";
import axios from "./axios";

function MorningEntry({ selectedDate, currentDateTime }) {
 
  const [currentDateTime2, setCurrentDateTime2] = useState(currentDateTime);
  useEffect(() => {
    setCurrentDateTime2(currentDateTime);
  }, [currentDateTime]);

  const [loading, setLoading] = useState(true);
  const [dateRaw, setDateRaw] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    raw1col1btnradio1: "NO",
    raw1col2btnradio2: "NO",
    raw1col3btnradio3: "NO",
    raw1col4btnradio4: "NO",
    raw1col5btnradio5: "NO",
    raw1col6btnradio6: "NO",
    raw2col1btnradio1: "NO",
    raw2col2btnradio2: "NO",
    raw2col3btnradio3: "NO",
    raw2col3btnradio3_date: currentDateTime2,
    raw2col4btnradio4: "NO",
    raw2col5btnradio5: "NO",
    raw2col6btnradio6: "NO",
    raw3col1btnradio1: "NO",
    raw3col1btnradio1_date: currentDateTime2,
    raw3col2btnradio2: "NO",
    raw3col2btnradio2_date: currentDateTime2,
    raw3col3btnradio3: "NO",
    raw3col4btnradio4: "NO",
    raw3col5btnradio5: "NO",
    raw3col6btnradio6: "NO",
    raw4col1btnradio1: "NO",
    raw4col2btnradio2: "NO",
    // date: selectedDate
  });
  // console.log(selectedOptions)
  useEffect(() => {
    if (selectedDate) {
      checkCurrentDate(selectedDate);
      // console.log(selectedDate)
    }
  }, [selectedDate]);

  const checkCurrentDate = (date) => {
    // console.log('ss')
    setLoading(true);
    axios
      .post("/check-date", { date })
      .then((response) => {
        // console.log(response.data)
        setDateRaw(response.data.items[0] || null);
        setLoading(false);
        //  console.log(dateRaw)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (dateRaw !== undefined && dateRaw !== null) {
      // console.log(currentDateTime)
      // console.log(dateRaw.a9_date)
      const initialOptions = {
        raw1col1btnradio1: dateRaw.a1 || "NO",
        raw1col2btnradio2: dateRaw.a2 || "NO",
        raw1col3btnradio3: dateRaw.a3 || "NO",
        raw1col4btnradio4: dateRaw.a4 || "NO",
        raw1col5btnradio5: dateRaw.a5 || "NO",
        raw1col6btnradio6: dateRaw.a6 || "NO",
        raw2col1btnradio1: dateRaw.a7 || "NO",
        raw2col2btnradio2: dateRaw.a8 || "NO",
        raw2col3btnradio3: dateRaw.a9 || "NO",
        raw2col3btnradio3_date: dateRaw.a9_date || currentDateTime2,
        raw2col4btnradio4: dateRaw.a10 || "NO",
        raw2col5btnradio5: dateRaw.a11 || "NO",
        raw2col6btnradio6: dateRaw.a12 || "NO",
        raw3col1btnradio1: dateRaw.a13 || "NO",
        raw3col1btnradio1_date: dateRaw.a13_date || currentDateTime2,
        raw3col2btnradio2: dateRaw.a14 || "NO",
        raw3col2btnradio2_date: dateRaw.a14_date || currentDateTime2,
        raw3col3btnradio3: dateRaw.a15 || "NO",
        raw3col4btnradio4: dateRaw.a16 || "NO",
        raw3col5btnradio5: dateRaw.a17 || "NO",
        raw3col6btnradio6: dateRaw.a18 || "NO",
        raw4col1btnradio1: dateRaw.a19 || "NO",
        raw4col2btnradio2: dateRaw.a20 || "NO",
        // date: selectedDate
      };
      setSelectedOptions(initialOptions);
    } else {
      setSelectedOptions({
        raw1col1btnradio1: "NO",
        raw1col2btnradio2: "NO",
        raw1col3btnradio3: "NO",
        raw1col4btnradio4: "NO",
        raw1col5btnradio5: "NO",
        raw1col6btnradio6: "NO",
        raw2col1btnradio1: "NO",
        raw2col2btnradio2: "NO",
        raw2col3btnradio3: "NO",
        raw2col3btnradio3_date: currentDateTime2,
        raw2col4btnradio4: "NO",
        raw2col5btnradio5: "NO",
        raw2col6btnradio6: "NO",
        raw3col1btnradio1: "NO",
        raw3col1btnradio1_date: currentDateTime2,
        raw3col2btnradio2: "NO",
        raw3col2btnradio2_date: currentDateTime2,
        raw3col3btnradio3: "NO",
        raw3col4btnradio4: "NO",
        raw3col5btnradio5: "NO",
        raw3col6btnradio6: "NO",
        raw4col1btnradio1: "NO",
        raw4col2btnradio2: "NO",
        // date: selectedDate,
      });
    }
  }, [dateRaw]);

  const handleDateTimeChange = (name, value) => {
    // console.log(value)
    setCurrentDateTime2(value);
    handleOptionChange(name, value);
    // console.log(value)
  };

  const handleOptionChange = (name, value) => {
    setSelectedOptions({
      ...selectedOptions,
      [name]: value,
      date: currentDateTime,
    });
  };

  const handleSubmit = (event) => {
    // console.log(dateRaw)
    setLoading(true);
    event.preventDefault();
    const data = {
      ...selectedOptions,
      date: selectedDate, // Include selectedDate in the payload
    };
    const apiUrl =
      dateRaw !== null && dateRaw !== undefined
        ? `/edit/${selectedDate}`
        : "/create";
    axios({
      method: dateRaw !== null && dateRaw !== undefined ? "put" : "post",

      url: apiUrl,
      data: data,
    })
      .then((response) => {
        setDateRaw(response.data.items[0] || null);
        setLoading(false);
        toast.success(response.data.message); 
      })
      .catch((error) => {
        console.error("Error sending data:", error);
         toast.error("Wrong Data Entered");
        setLoading(false);
      });
  };

  const m1Entries = [
    {
      name: "raw1col1btnradio1",
      header: 'Is <span style="color: red">Nifty Weekly</span> Expiry Today',
      needDate: "no",
    },
    {
      name: "raw1col2btnradio2",
      header:
        'Is <span style="color: red">Bank Nifty Weekly</span> Expiry Today',
      needDate: "no",
    },
    {
      name: "raw1col3btnradio3",
      header:
        'Is <span style="color: red">FIN NIFTY Weekly</span> Expiry Today',
      needDate: "no",
    },
    {
      name: "raw1col4btnradio4",
      header: 'Is today is <span style="color: red">Nifty Monthly</span> Expiry',
      needDate: "no",
    },
    {
      name: "raw1col5btnradio5",
      header: 'Is Today is <span style="color: red">Bank Nifty</span> Monthly Expiry',
      needDate: "no",
    },
    {
      name: "raw1col6btnradio6",
      header:
        'Is Today <span style="color: red">Nifty</span>Monthly Contract Starting',
      needDate: "no",
    },
    {
      name: "raw2col1btnradio1",
      header: 'Day <span style="color: red">Bfore Monthly Expiry</span>',
      needDate: "no",
    },
    {
      name: "raw2col2btnradio2",
      header:
        'Waiting for any <span style="color: red">Majour news</span> tomorrow/After Market Timings ',
      needDate: "no",
    },
    {
      name: "raw2col3btnradio3",
      header:
        'Is any RBI New is on the market Timings..? (Other Than calender News) <span style="color: red"</span>',
      needDate: "yes",
    },
    {
      name: "raw2col4btnradio4",
      header:
        'Is Today is the day <span style="color: red">after Heavy Up  Movement</span>',
      needDate: "no",
    },
    {
      name: "raw2col5btnradio5",
      header:
        'Is Today Is Today is the day <span style="color: red">after Heavy Down Movement</span>',
      needDate: "no",
    },
    {
      name: "raw2col6btnradio6",
      header:
        'Is Today is the day <span style="color: red">after Heavy Consolidation</span>',
      needDate: "no",
    },
    {
      name: "raw3col1btnradio1",
      header:
        'Waiting for any Majour news Today Market Timing  <span style="color: red"></span>',
      needDate: "yes",
    },
    {
      name: "raw3col2btnradio2",
      header: 'Any other National Special Day <span style="color: red"></span>',
      needDate: "yes",
    },
    {
      name: "raw3col3btnradio3",
      header:
        'Budget Day(Other Than calender News) <span style="color: red"</span>',
      needDate: "no",
    },
    {
      name: "raw3col4btnradio4",
      header: 'Main Election Day <span style="color: red"></span>',
      needDate: "no",
    },
    {
      name: "raw3col5btnradio5",
      header: 'Main Election Result Day <span style="color: red"></span>',
      needDate: "no",
    },
    {
      name: "raw3col6btnradio6",
      header: 'Local Election day<span style="color: red"></span>',
      needDate: "no",
    },
    {
      name: "raw4col1btnradio1",
      header: 'Local Election Result Day<span style="color: red"></span>',
      needDate: "no",
    },
    {
      name: "raw4col2btnradio2",
      header:
        'Is waiting any Indian Economy Related News, After Market?<span style="color: red"></span>',
      needDate: "no",
    },
  ];

  return (
    <div className="container-fluid border rounded pt-3">
       <ToastContainer
          position="top-left"
          autoClose={1000}
          closeOnClick={true}
        />
    
     
      <h3>Befor Indian Market Opening</h3>

      <form onSubmit={handleSubmit} className="border-bottom border-danger p-3">
        <div className="row">
          {m1Entries.map((entry, index) => (
            <div key={index} className="col-xs-6 col-sm-4 col-lg-2 d-flex">
              <M1Entry
                cardContent={{
                  header: entry.header,
                  name: entry.name,
                }}
                handleOptionChange={handleOptionChange}
                selectedOption={selectedOptions[entry.name]}
                DateRequired={{ needDate: entry.needDate }}
                currentDateTime2={currentDateTime2}
                handleDateTimeChange={handleDateTimeChange}
                raw2col3btnradio3_date={
                  selectedOptions["raw2col3btnradio3_date"]
                }
                raw3col1btnradio1_date={
                  selectedOptions["raw3col1btnradio1_date"]
                }
                raw3col2btnradio2_date={
                  selectedOptions["raw3col2btnradio2_date"]
                }
                loading={loading}
              />
            </div>
          ))}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success card_color blink"
            // data-toggle="button"
            aria-pressed="false"
          >
            Submit
          </button>
        )}
        
      </form>
      <br />
    </div>
  );
}

export default MorningEntry;
