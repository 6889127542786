import React,{useState,useEffect} from "react";
import Spinner from '../components/Spinner'

function IndianLiveMarketCard({
  cardContent,
  handleOptionChange,
  selectedOption,
  typeReq,
  selectedOptions2,
  loading
}) {

  const [divHide, setDivHide] = useState(false);


  useEffect(() => {
    // Update divHide based on selectedOptions2
    if (selectedOptions2[cardContent.name] === "YES") {
      setDivHide(true);
    } else {
      setDivHide(false);
    }
  }, [selectedOptions2, cardContent.name]);

  return (
    <div className="card flex-fill border-0 illustration">
      <div
        className="card-body p-0 d-flex flex-fill"
        style={{ minWidth: "150px" }}
      >
        <div className="row g-0 w-100 card_color">
          <div
            className="card-header"
            dangerouslySetInnerHTML={{ __html: cardContent.header }}
          />
          {
            loading ? <Spinner /> :
            <div className="card-body text-center">
              {typeReq && typeReq.radio === "YES" && (
                <div
                  className="btn-group text-center border"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name={cardContent.name}
                    id={`${cardContent.name}_YES`}
                    autoComplete="off"
                    value="YES"
                    onChange={handleOptionChange}
                    checked={selectedOption === "YES"}
                  />
                  <label
                    className="btn btn-outline-success"
                    htmlFor={`${cardContent.name}_YES`}
                  >
                    YES
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name={cardContent.name}
                    id={`${cardContent.name}_NO`}
                    autoComplete="off"
                    value="NO"
                    onChange={handleOptionChange} // Pass both name and value
                    checked={selectedOption === "NO"}
                  />
                  <label
                    className="btn btn-outline-danger"
                    htmlFor={`${cardContent.name}_NO`}
                  >
                    NO
                  </label>
                </div>
              )}
              {typeReq && typeReq.decimel === "YES" && (
                <div
                  className="card-body text-center"
                  style={{ width: "100%" }}
                >
                  <input
                    type="number"
                    className="form-control"
                    step="0.01"
                    name={cardContent.name}
                    value={selectedOptions2[cardContent.name]} // Bind value to selectedOption
                    onChange={handleOptionChange}
                    required
                  />
                </div>
              )}
              {typeReq && typeReq.percent === "YES" && (
                <div
                  className="card-body text-center"
                  style={{ width: "100%" }}
                >
                  <input
                    type="number"
                    className="form-control"
                    name={`${cardContent.name}_percent`}
                    step="25"
                    value={selectedOptions2[`${cardContent.name}_percent`]}
                    onChange={handleOptionChange}
                    required
                  />
                </div>
              )}

              {typeReq && typeReq.radioGain === "YES" && (
                <div
                  className="btn-group text-center border"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name={cardContent.name}
                    id={`${cardContent.name}_GAIN`}
                    autoComplete="off"
                    value="GAIN"
                    onChange={handleOptionChange} // Pass both name and value
                    checked={selectedOption === "GAIN"}
                  />
                  <label
                    className="btn btn-outline-success"
                    htmlFor={`${cardContent.name}_GAIN`}
                  >
                    GAIN
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name={cardContent.name}
                    id={`${cardContent.name}_NO`}
                    autoComplete="off"
                    value="NO"
                    onChange={handleOptionChange} // Pass both name and value
                    checked={selectedOption === "NO"}
                  />
                  <label
                    className="btn btn-outline-danger"
                    htmlFor={`${cardContent.name}_NO`}
                  >
                    NO
                  </label>
                  <div
                    className="btn-group text-center border"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name={cardContent.name}
                      id={`${cardContent.name}_LOSS`}
                      autoComplete="off"
                      value="LOSS"
                      onChange={handleOptionChange} // Pass both name and value
                      checked={selectedOption === "LOSS"}
                    />
                    <label
                      className="btn btn-outline-success"
                      htmlFor={`${cardContent.name}_LOSS`}
                    >
                      LOSS
                    </label>
                  </div>
                </div>
              )}
             
              {typeReq && typeReq.radioDouble === "YES"  && (
               
                <div className={`container ${divHide ? 'd-block' : 'd-none'}`}> 
                <select
                  className="form-select"
                  name={`${cardContent.name}_radio`}
                  value={selectedOptions2[`${cardContent.name}_radio`]}
                  onChange={handleOptionChange}
                > 
                  <option value="NO">select..</option>
                  <option value="HIGH">HIGH</option>
                  <option value="LOW">LOW</option>
                </select>
           
              </div>
            )}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default IndianLiveMarketCard;
