import React, { useState, useEffect ,useRef} from "react";
import LineGraph from "./LineGraph";
import "./ModalFullScreen.css";
import getCurrentDate from "../components/currentDate";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";

function NcoGraphChart() {
  const [loading, setLoading] = useState(true);
  const currentDate = getCurrentDate();
  const [todayDate, setTodayDate] = useState(currentDate);
  const [ncoValues, setNcoValues] = useState([]);
  const observer = useRef();
  const [page, setPage] = useState(1); // Track the current page number
  const [hasMore, setHasMore] = useState(true); // Track if there is more data to load
 
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    if (newDate !== todayDate) { 
      setPage(1); 
      setTodayDate(newDate); 
      setNcoValues([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post("/ncochartall", {
        date: todayDate,
        page: page, // Pass the current page number to the API
      })
      .then((response) => { 
        const newData = response.data.map((item) => ({
          niftyce: item.niftyce.map((entry) => ({
            nco_nifty_ce: entry.nco_nifty_ce,
            nifty_ce_date: entry.nifty_ce_date,
            nifty_ce_price_value: entry.nifty_ce_price_value,
          })),
          niftype: item.niftype.map((entry) => ({
            nco_nifty_pe: entry.nco_nifty_pe,
            nifty_pe_date: entry.nifty_pe_date,
            nifty_pe_price_value: entry.nifty_pe_price_value,
          })),
          bnfce: item.bnfce.map((entry) => ({
            nco_bnf_ce: entry.nco_bnf_ce,
            bnf_ce_date: entry.bnf_ce_date,
            bnf_ce_price_value: entry.bnf_ce_price_value,
          })),
          bnfpe: item.bnfpe.map((entry) => ({
            nco_bnf_pe: entry.nco_bnf_pe,
            bnf_pe_date: entry.bnf_pe_date,
            bnf_pe_price_value: entry.bnf_pe_price_value,
          })),
          expiryday: item.expiryday,
        }));
        setNcoValues((prevData) => [...prevData, ...newData]); // Append new data to existing data
        setHasMore(response.data.length > 0); // Set hasMore to false if there is no more data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [todayDate, page]); // Trigger fetch data when todayDate or page changes

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    // Initialize IntersectionObserver
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        // If bottom is reached and there is more data to load, increment page number
        setPage((prevPage) => prevPage + 1);
      }
    }, options);

    if (observer.current) {
      // Observe the bottom element
      observer.current.observe(document.querySelector(".bottom"));
    }


    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore]);


  // -----convert yyyy-mm-dd-- to -- dd-mm-yyyy----
  function formatDate(dateString) {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
 
  const labels = ["nco", "nco+1", "nco+2", "nco+3", "nco+4"];
  return (
    <div className="container font-italic">
      <h5>
        <span style={{ color: "lime" }}>NCO GRAPH </span>
        <input
          id="selectdate_dash"
          value={todayDate}
          onChange={handleDateChange}
          type="date"
          max={currentDate}
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
            color: "lime",
          }}
        />
      </h5>
      <div className="row mt-4">
        {ncoValues.map((item, index) => (
          <div key={index} className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body card_color">
                <div className="card-title border"  style={{ color: 'rgb(173, 255, 47)' }}>
                  <div className="row">
                    <div className="col">
                      Expiry Day: {formatDate(item.expiryday)}
                    </div>
                    {["NCO", "NCO+1", "NCO+2", "NCO+3", "NCO+4"].map((label, index) => (
                      <div className="col-xs-6 col-sm-6" key={index}>
                        <div className="row">
                          <div className="col">
                            {label} :{" "}
                            {item.niftyce[index] &&
                            item.niftyce[index].nifty_ce_date
                              ? formatDate(item.niftyce[index].nifty_ce_date)
                              : null}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {loading ? (
                  <Spinner />
                ) : (
                  <LineGraph
                    data1={item.niftyce.map((entry) =>
                      parseInt(entry.nifty_ce_price_value)
                    )}
                    data2={item.niftype.map((entry) =>
                      parseInt(entry.nifty_pe_price_value)
                    )}
                    data3={item.bnfce.map((entry) =>
                      parseInt(entry.bnf_ce_price_value)
                    )}
                    data4={item.bnfpe.map((entry) =>
                      parseInt(entry.bnf_pe_price_value)
                    )}
                    labels={labels}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
       
       <div className="bottom"></div>
              
    </div>
  );
}

export default NcoGraphChart;
