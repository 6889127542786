import axios from '../pages/axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser(){
    const navigate = useNavigate();

    const getToken = () =>{
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }



    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());

    const saveToken = (user,token) =>{
        sessionStorage.setItem('token',JSON.stringify(token));
        sessionStorage.setItem('user',JSON.stringify(user));

        setToken(token);
        setUser(user);
        navigate('/');
    }

    const logout = () => {
        sessionStorage.clear();
        navigate('/login');
    }
    // -----------last add to 401 429 error logout--
    const handleResponseError = error => {
        if (error.response) {
            if (error.response && error.response.status === 401) {
                logout();
            } 
            if (error.response.status === 429) {
                // Implement retry logic with exponential backoff
                const retryAfter = parseInt(error.response.headers['retry-after']) || 1;
                return new Promise(resolve => setTimeout(resolve, retryAfter * 1000))
                    .then(() => axios(error.config));
            } else if (error.response.status === 401) {
                logout();
            }
        }
        return Promise.reject(error);
    };


    axios.interceptors.response.use(response => response, handleResponseError);
// -------------------------------------

    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const http = axios;
    return {
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}