import "./App.css";
import Dashboard from "./pages/Dashboard";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login"
import Register from "./auth/Register";
import useAuthUser from "./auth/AuthUser"; // Import useAuthUser here

function App() {
  const { getToken } = useAuthUser(); // Call useAuthUser inside the functional component

  if(!getToken()){
    return <Login />
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Dashboard />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/tradeRegister" element={<Register />} />
      </Routes>
    </div>
  );
}

export default App;
