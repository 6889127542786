import React, { useState, useEffect } from "react";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NcoNiftyCE from "./NcoNiftyCE";
import NcoNiftyPE from "./NcoNiftyPE";

function NiftyCePeRoute({
  selectedDate,
  mergedOptions,
  allNcoPrice,
  ncoMainValue,
}) {
  const [loading, setLoading] = useState(true);
  const [niftyExpiryDay, setNiftyExpiryDay] = useState("");
  const [expDay, setExpDay] = useState([]);
  const [volatileformData, setvolatileformData] = useState({
    volatileOption: "no",
    atmPoints: "",
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post("/nco_nifty_volatile_movement_get_data", {
        date: selectedDate,
      })
      .then((response) => {
        setvolatileformData(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Server Error");
      });
  }, [selectedDate]);

  useEffect(() => {
    setLoading(true);
    axios
      .post("/nco_nifty_expiry_days", {
        date: selectedDate,
      })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          setExpDay([]);
          setNiftyExpiryDay("");
        }
        setExpDay(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectedDate]);

  useEffect(() => {
    if (expDay.length > 0) {
      setNiftyExpiryDay(expDay[0]); // Set initial value to the first value in expDay
    }
  }, [expDay]);
  const handleExpDay = (e) => {
    setNiftyExpiryDay(e.target.value);
  };
  // ------volatile form -----------

  const handlevolatilechange = (e) => {
    const { name, value } = e.target;
    setvolatileformData({ ...volatileformData, [name]: value });
  };

  const handleVolatileSubmit = (e) => {
    e.preventDefault();
    if (!selectedDate) return;
    setLoading(true);
    const data = {
      volatileformData: volatileformData,
      selectedDate: selectedDate,
    };
    axios
      .post("/nco_nifty_volatile_movement", {
        data: data,
      })
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("Wrong Data");
      });
  };
  return (
    <>
      <div className="row pb-4">
        <div className="col">
          <label htmlFor="resulttype">Nifty Expiry Day:</label>
        </div>
        <div className="col">
          {loading ? (
            <Spinner />
          ) : (
            <select
              className="form-control"
              name="resulttype"
              value={niftyExpiryDay}
              onChange={handleExpDay}
              style={{ minWidth: "100px" }}
              required
            >
              {/* {expDay.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))} */}

              {expDay.map((option, index) => {
                // Split the date string by "-"
                const parts = option.split("-");

                // Reorder the parts to get the "dd-mm-yyyy" format
                const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

                return (
                  <option key={index} value={option}>
                    {formattedDate}
                  </option>
                );
              })}
              
            </select>
          )}
        </div>
      </div>
      <div className="row pt-2 pb-2">
        <NcoNiftyCE
          selectedDate={selectedDate}
          mergedOptions={mergedOptions}
          niftyExpiryDay={niftyExpiryDay}
          allNcoPrice={allNcoPrice}
          ncoMainValue={ncoMainValue}
        />
      </div>
      <div className="row border border-info rounded pt-1 pb-1 bg-secondary">
        <label>High Volatile Movement Happened</label>
        <ToastContainer
          position="top-left"
          autoClose={2000}
          closeOnClick={true}
        />
        <form onSubmit={handleVolatileSubmit}>
          <table className="table table-striped table-bordered border bg-secondary">
            <thead>
              <tr>
                <th>
                  <label>select</label>
                </th>
                <th>
                  <label>ATM Points</label>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select
                    className="form-control"
                    name="volatileOption"
                    value={volatileformData.volatileOption}
                    onChange={handlevolatilechange}
                    required
                  >
                    <option value="no">No</option>
                    <option value="both">Both Side</option>
                    <option value="up">Up Side</option>
                    <option value="down">Down Side</option>
                  </select>
                </td>
                <td>
                  <input
                    className="form-control"
                    type="number"
                    name="atmPoints"
                    value={volatileformData.atmPoints}
                    onChange={handlevolatilechange}
                    required
                  />
                </td>
                <td>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ minWidth: 60, maxWidth: 100 }}
                    >
                      Save
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div className="row  pt-2 pb-2">
        <NcoNiftyPE
          selectedDate={selectedDate}
          mergedOptions={mergedOptions}
          niftyExpiryDay={niftyExpiryDay}
          allNcoPrice={allNcoPrice}
          ncoMainValue={ncoMainValue}
        />
      </div>
    </>
  );
}

export default NiftyCePeRoute;
