// Modal.js
import ReactModal from 'react-modal';

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000
        },
        content: {
          width: '70%',
          height: '70%',
          margin: 'auto'
        }
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
